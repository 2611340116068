// Forms
//
// Base form elements.
//
//
form {
  margin: 0 0 1em 1em;
  &[block="block-clearlinux-theme-search"] {
    margin-top: 25px;
    input[type="text"]{
      max-width: 100%;
      width: 100%;
    }
    .form-actions{
      text-align: center;
    }
  }
}

input[type="text"],
input[type="password"],
input[type="date"] {
  border-radius: 0;
  background-color: $white;
  font-family: inherit;
  border: 1px solid $light_blue_gray;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875em;
  margin: 0 0 1em;
  padding: 0.5em;
  height: 2.3125em;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
textarea:focus {
  box-shadow: 0 0 5px $light_blue_gray;
  border-color: $light_blue_gray;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
textarea:focus {
  background: $light_blue_gray;
  border-color: $light_blue_gray;
  outline: none;
}
input[type="text"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
textarea[disabled] {
  opacity: 0.5;
}
input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
  margin: 0 0 1em;
}
input[type="file"] {
  width: 100%;
}

fieldset {
  border: solid 1px $charcol;
  padding: 1.25em;
  margin: 1.125em 0;
}

fieldset legend {
  font-family: $cl_intel_font-family-bold;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0;
  color: $button;
}

.form--inline {
  fieldset {
    border: none;
    display: block;
    margin: 0;
    padding: 0;

    .form-item {
      margin: 0 25px 25px 0;
    }

    .form-type-button, .form-type-submit {
      position: relative;
      top: 25px;
    }
  }
}

.toolbar__container {
  .form--inline {
    display: block;

    .form-item {
      display: inline-flex;
      margin-right: 15px;

      label {
        margin-right: 10px;
        position: relative;
        top: 15px;
        white-space: nowrap;
      }

      &.form-type-select {
        label {
          white-space: nowrap;
        }
      }
    }
  }
}
@media only screen and (min-width: $cl_md) {
  .form--inline {
    display: block;

    .form-item {
      float: left;
    }
  }
}

// Error conditions ////////////////////////////
.error input,
.error textarea,
.error select {
  border-bottom: 2px solid $form_error !important;
}

.error span.error-message {
  display: block;
  font-size:12px;
  color: $dark-gray;
  letter-spacing:0.55px;
  line-height:14px;
  text-align:left;

  &:before {
    content: '*';
    color: $form_error;
    padding-right: 5px;
  }
}

.verified {
  label:before {
    content: '\f00c';
    font-family: FontAwesome;
    color: $accent_color;
    position: relative;
    top: 5px;
    left: 396px;
    margin-right: -17px;
  }
}
// Base Form
//
// Markup: form.twig
//
// Style guide: form.form
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"] {
  border-radius: 0;
  background-color: $white;
  font-family: inherit;
  border: 1px solid $light_blue_gray;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875em;
  margin: 0 0 1em;
  padding: 0.5em;
  height: 2.3125em;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
}
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus {
  box-shadow: 0 0 5px $light_blue_gray;
  border-color: $light_blue_gray;
}
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus {
  background: $light_blue_gray;
  border-color: $light_blue_gray;
  outline: none;
}

input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="month"][disabled],
input[type="week"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="time"][disabled],
input[type="url"][disabled] {
  opacity: 0.5;
}
