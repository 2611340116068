@import '../bower_components/susy/sass/susy';
@import '../bower_components/breakpoint-sass/stylesheets/breakpoint';
/**
  Normal reset
*/
@import "reset";

/**
  Config file that contains centralized variables
*/
@import "config";

@import '../bower_components/font-awesome/scss/font-awesome';

// Base
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Form
//
// These are the default form styles applied to HTML elements.
//
// Style guide: form
@import "base/_base.scss";
@import "base/_colorbox.scss";
@import "base/_errors.scss";
@import "base/_form.scss";
@import "base/_main.scss";
@import "base/_tasks.scss";

// Layout
//
// These are the layout rules.
//
// Style guide: layout


// Mixins
//
// These are the mixins.
//
// Style guide: mixins
@import "mixins/_button_size.scss";
@import "mixins/_transform.scss";
@import "mixins/_transition.scss";

// Components
//
// Design components are reusable styling that can be applied using just the CSS
// class names specified in the component. Also known as design 'modules'.
//
// Style guide: components
@import "components/components/article_list/_article_list.scss";
@import "components/components/article/_article.scss";
@import "components/components/aside/_software-aside-category.scss";
@import "components/components/aside/_software-aside-search.scss";
@import "components/components/back_to_top/_back_to_top.scss";
@import "components/components/banner/_banner.scss";
@import "components/components/base/_color_palette.scss";
@import "components/components/base/_forms.scss";
@import "components/components/base/_headlines.scss";
@import "components/components/base/_layout.scss";
@import "components/components/base/_list.scss";
@import "components/components/base/_messages.scss";
@import "components/components/base/_nav.scss";
@import "components/components/base/_profile.scss";
@import "components/components/base/_quote.scss";
@import "components/components/base/_share.scss";
@import "components/components/base/_subheader.scss";
@import "components/components/base/_text.scss";
@import "components/components/base/_title.scss";
@import "components/components/block_image/_block_image.scss";
@import "components/components/button/_button.scss";
@import "components/components/button/_software-button.scss";
@import "components/components/call_to_action/_call_to_action.scss";
@import "components/components/card_blog/_card_blog.scss";
@import "components/components/card_dynamic_content/_card_dynamic_content.scss";
@import "components/components/card_dynamic_item/_card_dynamic_item.scss";
@import "components/components/card_icon/_card_icon.scss";
@import "components/components/card_menu/_card_menu.scss";
@import "components/components/card_stack/_card_stack.scss";
@import "components/components/card/_card.scss";
@import "components/components/cards_icon_list/_cards_icon_list.scss";
@import "components/components/code_snippet/_code_snippet.scss";
@import "components/components/community_counter/_community_counter.scss";
@import "components/components/community_list/_community_list.scss";
@import "components/components/community/_community.scss";
@import "components/components/download_card/_download_card.scss";
@import "components/components/event/_event.scss";
@import "components/components/feature_list/_feature_list.scss";
@import "components/components/feature/_feature.scss";
@import "components/components/hero_carousel/_hero_carousel.scss";
@import "components/components/highlight/_highlight.scss";
@import "components/components/highlights_carousel/_highlights_carousel.scss";
@import "components/components/image_gallery/_image_gallery.scss";
@import "components/components/leading_text/_leading_twig.scss";
@import "components/components/link/_link.scss";
@import "components/components/list_card/_list_card.scss";
@import "components/components/pagination/_software-pagination.scss";
@import "components/components/project/_project.scss";
@import "components/components/search_input/_search_input.scss";
@import "components/components/secondary_link/_secondary_link.scss";
@import "components/components/social_channel_list/_social_channel_list.scss";
@import "components/components/social_channel_menu_item/_social_channel_menu_item.scss";
@import "components/components/social_channel_menu/_social_channel_menu.scss";
@import "components/components/social_channel/_social_channel.scss";
@import "components/components/software/_software-item.scss";
@import "components/components/software/_software-search-result.scss";
@import "components/components/tab/_software-tab.scss";
@import "components/components/table/_table.scss";
@import "components/components/text_block/_text_block.scss";
@import "components/components/tooltip/_software-tooltip.scss";
@import "components/components/two_columns/_two_column.scss";
@import "components/components/video/_video.scss";
@import "components/layout/_containers.scss";
@import "components/layout/_layout.scss";
@import "components/layout/_sidebars.scss";
@import "components/layout/blog/_blog.scss";
@import "components/layout/blogs/_blogs.scss";
@import "components/layout/breadcrumb/_breadcrumb.scss";
@import "components/layout/documentation/_documentation.scss";
@import "components/layout/downloads_content/_downloads_content.scss";
@import "components/layout/downloads/_downloads.scss";
@import "components/layout/features/_features.scss";
@import "components/layout/footer/_footer.scss";
@import "components/layout/header/_header.scss";
@import "components/layout/homepage/_homepage.scss";
@import "components/layout/page_header_tabs/_page_header_tabs.scss";
@import "components/layout/page_header/_page_header.scss";
@import "components/layout/page/_page.scss";
@import "components/layout/search/_search.scss";
@import "components/layout/sidebar_nav/_sidebar_nav.scss";
@import "components/layout/software/_software.scss";
@import "components/layout/tabs/_tabs.scss";
@import "components/layout/toolbar/_toolbar.scss";
@import "components/layout/wrapper/_wrapper.scss";


// States
//
// States are variations that are usually applied via js, such as `.is-active`.
//
// Style guide: states
@import "state/_states.scss";
