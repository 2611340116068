#software {
  .bundle__block {
    background-color: $white;
    padding: 20px 20px 10px;
    transition: all 0.3s ease;

    .bundle__info__details {
      min-height: 115px;
      border-bottom: 1px solid $gray_light_2;
      margin-bottom: 8px;
    }

    .bundle__item {
      display: flex;
    }
  }

  .bundle__ico {
    width: 30px;
    height: 30px;
    display: contents;

    img {
      width: 30px;
      height: 30px;
      margin: 0 !important;
    }
  }

  .bundle__info__block {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    margin-bottom: 0;
    position: relative;
    overflow: visible;  

    p span {
      display: inline-block;
      overflow: hidden;
      line-height: 21px;
      max-height: 42px;
      word-break: break-word;
    }

    p {
      color: $black_light;
      border-bottom: none;
      font-size: 18px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 3px;
      line-height: 20px;
    }

    p:first-child {
      color: $text-light;
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .bundle__long__title:hover {
      color: $clear_blue;
      cursor: pointer;

      &:before {
        display: inline;
        position: absolute;
        bottom: 40px;
        background-color: #307894;
        color: $white;
        font-size: 14px;
        line-height: 16px;
        min-width: 200px;
        width: 100%;
        padding: 5px 15px;
        z-index: 2;
        content: attr(data-content);
      }
    }
  }

  .category__block > div:first-child {
    display: block;
    margin-bottom: 0;
  }

  .category__block {
    color: $text-light;
    font-size: 12px;
    line-height: 14px;
    max-height: 30px;
    text-transform: uppercase;
    margin-left: 50px;
    overflow: hidden;
    word-break: break-word;

    .field__items {
      display: block;
      padding-left: 10px;
      overflow: hidden;
      max-height: 28px;
      line-height: 14px;
    }

    .field__item:not(:last-child):after {
      content: ',';
      margin-right: 5px;
    }
  }

  .bundle__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .bundle__details {
      color: #33bef2;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: none;

      &:hover {
        color: $clear_blue !important;
        border-bottom: none;
      }

      &:focus {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: $cl_sm) {
  #software {
    .view-bundles-search-view {
      .container {
        display: none;
      }
    }

    .bundle__block {
      margin: 0 10px;
    }
  }
}
