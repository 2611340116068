// Social Channel Menu Item
//
// Components Social Channel Menu Item
//
// Markup: social_channel_menu_item.twig
//
// Style guide: components.social_channel_menu_item
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.social_channel__menu_item {
  display: flex;

  .fa {
    margin: 0 $comp_space_sm 0 0;
    text-align: left;
    font-size: 20px;
    color: $cl_font-color;
  } 

  .social_channel__link {
    padding: $comp_space_sm/2;

    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }
  }

  a.social_channel__link {
    display: block;
    width: 100%;

    &:hover, &:active, &:focus {
      background: $gray_light_2;
      color: $intel;

      .fa, span {
        color: $intel;
      }
    }
  }

  .social_channel__title {
    font-size: 14px;
    font-family: $cl_intel_font-family;
    color: $cl_font-color;
    line-height: 21px;
    text-align: left;
    margin: 0;
  }
}