// Article List
//
// Component Article List
//
// Markup: article_list.twig
//
// .article_list--left - Style 2 - Title alignment left
//
// Style guide: components.article_list
.article_list {
  margin-bottom: 30px;

  .article_list__title {
    font-family: $cl_intel_font-family-light;
    font-size: 32px;
    line-height: 37.5px;
    text-align: center;
    color: $black_light;
  }

  &.article_list--left {
    .article_list__title {
      text-align: left;
    }
  }

  .article_list__items {
    margin-top: 50px;
    list-style: none;
    padding: 0;
  }

  .article_list__call_to_action {
    text-align: center;

    .pager {
      text-align: center;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item {
        display: inline-block;
      }
    }
  }

  .article_list__rss {
    float: right;
  }

  .article__image_wrapper {
    display: none;

    @media only screen and (min-width: $cl_md) {
      display: inline-block;
    }
  }
}

.article_list__call_to_action {
  margin-top: $padding_horizontal;
  margin-bottom: $padding_horizontal;

  .pager {
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      display: inline-block;
      margin: 0;
    }
  }
}

.article_list--left {
  .article_list__title {
    text-align: left;
  }
}
