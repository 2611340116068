// Events
//
// Components event
//
// Markup: event.twig
//
// Style guide: components.event

.event {
  margin: $padding_small 0;

  &:last-child {
    margin-bottom: 0;
  }

  width: 100%;
  display: flex;
  align-items: flex-start;

  &__date {
    margin: 0 $padding_small 0 0;
    display: none;

    h5 {
      color: $code-text;
      text-align: center;
    }

    h4 {
      font-size: 22px;
      text-align: center;
    }

    @media only screen and (min-width: $cl_sm) {
      display: block;
    }
  }

  &__description {
    h3 {
      margin-bottom: 0;
      font-size: $fsz-app-tile-heading;
      font-family: $cl_intel_font-family-bold;
    }

    p {
      margin-top: $padding_small;
      font-size: $fsz-app-tile-body;

      b {
        font-weight: bold;
      }

      i {

        &.fa {
          font-size: $fsz-app-tile-body;
          margin-right: $padding_small;
          width: 20px;
          text-align: center;
        }
      }
    }

    .link {
      margin: $padding_small 0 0;
      font-size: $fsz-app-tile-link;
    }
  }
}