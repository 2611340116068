// Color palatte
//
// Layout Color Palette
//
// Markup: color_palette.twig
//
// Style guide: base.color_palette
.Color {
  width: 100%;
  &__item {
    margin-bottom: 25px;
    @include span(24);
    @media only screen and (min-width: $cl_mobile) {
      @include span(12);
    }
    @media only screen and (min-width: $cl_sm) {
      @include span(8);
    }
    @media only screen and (min-width: $cl_md) {
      @include span(6);
    }
  }
  &__header {
    width: 100%;
    height: 130px;
  }
  &__body {
    border: solid 1px $grayish;
  }
  &__title {
    margin: 5px;
  }
  &__description {
    margin: 5px;
    color: $gastly;
  }
}
