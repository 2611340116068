// Download card
//
// Components Download card
//
// Markup: download_card.twig
//
// Style guide: components.download_card
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.download-card {
  box-sizing: border-box;
  padding-bottom: $comp_space_sm;
  padding-top: $comp_space_sm;

  @media (min-width: $cl_md) {
    @include span(8);
  }

  @media (min-width: $cl_xl) {
    @include span(6);
  }

  &__title {
    color: $intel;
    font-family: $cl_intel_font-family-bold;
    margin-bottom: $comp_space_sm;
  }

  &__desc {
    margin-bottom: $comp_space_sm;
  }

  &__action {
    @include container;
    border-bottom: 1px solid $submenu_border;
    margin-bottom: 0;
    padding: $comp_space_sm/2 0;

    &:last-child {
      border-bottom: none;
    }

    &s {
      list-style-type: none;
      margin: 0;
      padding-left: 0;
    }

    &__cta {
      .call_to_action {
        margin-right: $comp_space_sm/2;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__footer {
    color: $intel;
    margin: $comp_space_sm 1.04167%;
  }
}
