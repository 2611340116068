$comp_space_sm: 1.3%;
$comp_space_md: 2.6%;
$comp_space_lg: 5.2%;
$comp_space_xl: 10.4%;

$comp_line: if($line, $line, #d1d3d5);

$container_xs: if($cl_mobile, $cl_mobile, 479px);
$container_sm: if($cl_sm, $cl_sm, 768px);
$container_md: if($cl_md, $cl_md, 992px);
$container_lg: if($cl_lg, $cl_lg, 1025px);
$container_xl: if($cl_xl, $cl_xl, 1200px);

.margin-xl--left,
%margin-xl--left {
  margin-left: $comp_space_xl;
}

.margin-xl--right,
%margin-xl--right {
  margin-right: $comp_space_xl;
}

.margin-xl--bottom,
%margin-xl--bottom {
  margin-bottom: $comp_space_xl;
}

.margin-xl--top,
%margin-xl--bottom {
  margin-top: $comp_space_xl;
}

.margin-xl--top-bottom,
%margin-xl--top-bottom {
  margin-bottom: $comp_space_xl;
  margin-top: $comp_space_xl;
}

.margin-xl--left-right,
%margin-xl--left-right {
  margin-left: $comp_space_xl;
  margin-right: $comp_space_xl;
}

.margin-lg--left,
%margin-lg--left {
  margin-left: $comp_space_lg;
}

.margin-lg--right,
%margin-lg--right {
  margin-right: $comp_space_lg;
}

.margin-lg--bottom,
%margin-lg--bottom {
  margin-bottom: $comp_space_lg;
}

.margin-lg--top,
%margin-lg--top {
  margin-top: $comp_space_lg;
}

.margin-lg--top-bottom,
%margin-lg--top-bottom {
  margin-bottom: $comp_space_lg;
  margin-top: $comp_space_lg;
}

.margin-lg--left-right,
%margin-lg--left-right {
  margin-left: $comp_space_lg;
  margin-right: $comp_space_lg;
}

.margin-md--left,
%margin-md--left {
  margin-left: $comp_space_md;
}

.margin-md--right,
%margin-md--right {
  margin-right: $comp_space_md;
}

.margin-md--bottom,
%margin-md--bottom {
  margin-bottom: $comp_space_md;
}

.margin-md--top,
%margin-md--top {
  margin-top: $comp_space_md;
}

.margin-md--top-bottom,
%margin-md--top-bottom {
  margin-bottom: $comp_space_md;
  margin-top: $comp_space_md;
}

.margin-md--left-right,
%margin-md--left-right {
  margin-left: $comp_space_md;
  margin-right: $comp_space_md;
}

.margin-sm--left,
%margin-sm--left {
  margin-left: $comp_space_sm;
}

.margin-sm--right,
%margin-sm--right {
  margin-right: $comp_space_sm;
}

.margin-sm--bottom,
%margin-sm--bottom {
  margin-bottom: $comp_space_sm;
}

.margin-sm--top,
%margin-sm--top {
  margin-top: $comp_space_sm;
}

.margin-sm--top-bottom,
%margin-sm--top-bottom {
  margin-top: $comp_space_sm;
  margin-bottom: $comp_space_sm;
}

.margin-sm--left-right,
%margin-sm--left-right {
  margin-left: $comp_space_sm;
  margin-right: $comp_space_sm;
}

.margin-lg--top-bottom,
%margin-lg--top-bottom {
  margin-bottom: $comp_space_lg;
  margin-top: $comp_space_lg;
}

.margin-lg--left-right,
%margin-lg--left-right {
  margin-left: $comp_space_lg;
  margin-right: $comp_space_lg;
}

.padding-xl--left,
%padding-xl--left {
  padding-left: $comp_space_xl;
}

.padding-xl--right,
%padding-xl--right {
  padding-right: $comp_space_xl;
}

.padding-xl--bottom,
%padding-xl--bottom {
  padding-bottom: $comp_space_xl;
}

.padding-xl--top,
%padding-xl--top {
  padding-top: $comp_space_xl;
}

.padding-xl--top-bottom,
%padding-xl--top-bottom {
  padding-bottom: $comp_space_xl;
  padding-top: $comp_space_xl;
}

.padding-xl--left-right,
%padding-xl--left-right {
  padding-left: $comp_space_xl;
  padding-right: $comp_space_xl;
}

.padding-lg--left,
%padding-lg--left {
  padding-left: $comp_space_lg;
}

.padding-lg--right,
%padding-lg--right {
  padding-right: $comp_space_lg;
}

.padding-lg--bottom,
%padding-lg--bottom {
  padding-bottom: $comp_space_lg;
}

main {
  .padding-lg--bottom {
    padding-bottom: $comp_space_md;
  }
}

.padding--top-bottom {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-lg--top,
%padding-lg--top {
  padding-top: $comp_space_lg;
}

.padding-lg--top-bottom,
%padding-lg--top-bottom {
  padding-bottom: $comp_space_lg;
  padding-top: $comp_space_lg;
}

.padding-lg--left-right,
%padding-lg--left-right {
  padding-left: $comp_space_lg;
  padding-right: $comp_space_lg;
}

.padding-md--left,
%padding-md--left {
  padding-left: $comp_space_md;
}

.padding-md--right,
%padding-md--right {
  padding-right: $comp_space_md;
}

.padding-md--bottom,
%padding-md--bottom {
  padding-bottom: $comp_space_md;
}

.padding-md--top,
%padding-md--top {
  padding-top: $comp_space_md;
}

.padding-md--top-bottom,
%padding-md--top-bottom {
  padding-bottom: $comp_space_md;
  padding-top: $comp_space_md;
}

.padding-md--left-right,
%padding-md--left-right {
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
}

.padding-sm--left,
%padding-sm--left {
  padding-left: $comp_space_sm;
}

.padding-sm--right,
%padding-sm--right {
  padding-right: $comp_space_sm;
}

.padding-sm--bottom,
%padding-sm--bottom {
  padding-bottom: $comp_space_sm;
}

.padding-sm--top,
%padding-sm--top {
  padding-top: $comp_space_sm;
}

.padding-sm--top-bottom,
%padding-sm--top-bottom {
  padding-top: $comp_space_sm;
  padding-bottom: $comp_space_sm;
}

.padding-sm--left-right,
%padding-sm--left-right {
  padding-left: $comp_space_sm;
  padding-right: $comp_space_sm;
}

.border-light--top,
%border-light--top {
  border-top: 1px solid $comp_line;
}

.border-light--bottom,
%border-light--bottom {
  border-bottom: 1px solid $comp_line;
}

.border-light--left,
%border-light--left {
  border-left: 1px solid $comp_line;
}

.border-light--right,
%border-light--right {
  border-right: 1px solid $comp_line;
}

.container-xs,
%container-xs {
  max-width: $container_md;
  margin: 0 auto;
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
  width: 100%;
  box-sizing: border-box;
}

.container-sm,
%container-sm {
  max-width: $container_sm;
  margin: 0 auto;
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
  width: 100%;
  box-sizing: border-box;
}

.container-md,
%container-md {
  max-width: $container_md;
  margin: 0 auto;
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
  width: 100%;
  box-sizing: border-box;
}

.container-lg,
%container-lg {
  max-width: $container_lg;
  margin: 0 auto;
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
  width: 100%;
  box-sizing: border-box;
}

.container-xl,
%container-xl {
  max-width: $container_xl;
  margin: 0 auto;
  padding-left: $comp_space_md;
  padding-right: $comp_space_md;
  width: 100%;
  box-sizing: border-box;
}

.bg-gradient--blue,
%bg-gradient--blue {
  background: #10537C;
  background: -webkit-linear-gradient(top right, #10537C 0%, #072946 100%);
  background: -o-linear-gradient(top right, #10537C 0%, #072946 100%);
  background: linear-gradient(to bottom left, #10537C 0%, #072946 100%);
  color: #fff;
}

.bg-gradient--light-blue,
%bg-gradient--light-blue {
  background: #064F8B;
  background: -webkit-linear-gradient(296deg, #064F8B 0%, #006EC8 33%, rgba(39,190,243,0.85) 92%, #3CC6E5 96%, #70DAC5 99%, #43C9E1 100%, #7CDEBD 100%, #7DDFBC 100%, #85E2B7 100%);
  background: -o-linear-gradient(296deg, #064F8B 0%, #006EC8 33%, rgba(39,190,243,0.85) 92%, #3CC6E5 96%, #70DAC5 99%, #43C9E1 100%, #7CDEBD 100%, #7DDFBC 100%, #85E2B7 100%);
  background: linear-gradient(26deg, #064F8B 0%, #006EC8 33%, rgba(39,190,243,0.85) 92%, #3CC6E5 96%, #70DAC5 99%, #43C9E1 100%, #7CDEBD 100%, #7DDFBC 100%, #85E2B7 100%);
}

.bg-gradient--light,
%bg-gradient--light {
  background: #EDEDED;
  background: -webkit-linear-gradient(313deg, #EDEDED 0%, #FFFFFF 18.66, #E2E9FD 100%);
  background: -o-linear-gradient(313deg, #EDEDED 0%, #FFFFFF 18.66, #E2E9FD 100%);
  background: linear-gradient(43deg, #EDEDED 0%, #FFFFFF 18.66, #E2E9FD 100%);
}

.clearfix,
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.logged-in {
  main {
    min-height: calc(100vh - 75px);
  }
}

main {
  @extend %clearfix; 
  flex: auto; //changed from flex: 1 to auto to fix min-height issue in IE
  position: relative;
}