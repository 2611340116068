%code {
  display: block;
  height: auto;
  padding: 10px 10px 10px 30px;
  position: relative;
  text-align: left;
  color: #fff;
  background: transparent;
  box-sizing: border-box;

  @media only screen and (min-width: $cl_sm) {
    min-width: 100%;
  }
  &.active {
    &:after {
      display: block;
    }
  }
}

code {
  &.hljs {
    @extend %code;
  }
}

[class^=highlight-],
pre {
  position: relative;

  button.clipboard {
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    opacity: 1;
    padding: 2px 6px;
    position: absolute;
    border: initial;
    background: transparent;
    top: 9px;
    left: 4px;
    z-index: 1;
    outline: none;

    .fa {
      color: $intel;
      font-size: 20px;
    }

    &.tooltip {
      opacity: 0;
      -webkit-transition: opacity .3s ease-in-out;
      -o-transition: opacity .3s ease-in-out;
      transition: opacity .3s ease-in-out;

      &:after {
        color: $dark;
        background: rgba(241,243,248,0.8);
        padding: 5px 10px;
        position: absolute;
        top: 5px;
        left: 30px;
        content: "Copied";
        opacity: 1;
        outline: none;
      }
    }
  }

  pre {
    @extend %code;
    background-color: $dark;
  }
}
