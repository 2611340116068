// Text
//
// Base text
//
// Markup: text.twig
//
// Style guide: base.text
.txt {
  &__intel {
    color: $intel;
  }
}
