.Subheader {
  width: 100%;
  margin: -5px 0 0 0;
  &__item {
    font-family: $cl_intel_font-family-bold;
    font-size: 0.875em;
    color: $gastly;
    letter-spacing: 0.12px;
    text-align: left;
    margin: 0 0 20px 0;
    text-transform: capitalize;
  }
}
