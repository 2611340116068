
form {
  input[type="text"],
  input[type="password"],
  textarea, select {
    font-family: $cl_intel_font-family;
    font-size: 16px;
    color: $dark_gray;
    background: $gray_light_2;
    padding: 10px 16px;
    width: auto;
    width: 418px;
    max-width: 418px;
    box-shadow: none;
    height: 40px;
    margin-top: 8px;
    border: none;
    display: block;

    &::placeholder {
      opacity: 1
    }

    &:disabled {
      opacity: 0.5;
    }

    &.error {
      border: 0;
      border-bottom: solid 2px $form_error;
    }
  }

  textarea {
    width: 386px;
    max-width: 386px;
    height: 150px;
  }

  select {
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 0;
  }

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 5px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid $gray_alt;
    background-color: $white;
    border-radius: 50%;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  input[type="checkbox"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 0;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid $gray_alt;
    background-color: $white;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    background-color: $intel;
    border: 2px solid $intel;

    &:disabled {
      opacity: 0.5;
      cursor: none;
    }
  }

  input[type="checkbox"]:checked + label {
    &:after {
      content: '\f00c';
      font-family: FontAwesome;
      color: $white;
      position: absolute;
      left: -33px;
      top: 3px;
    }
  }

  .switch-wrapper {

    .switch {
      position: relative;
      display: block;
      width: 50px;
      height: 8px;
      overflow: visible;
      margin: 25px 0;
      left: 35px;

      &:after {
        content: 'On';
        position: absolute;
        top: -5px;
        left: 60px;
      }

      &:before {
        content: 'Off';
        position: absolute;
        top: -5px;
        left: -35px;
      }

      input {
        display:none;

        &:checked + .slider {
          background-color: $intel;

          &:before {
            -webkit-transform: translateX(25px);
            -ms-transform: translateX(25px);
            transform: translateX(25px);
          }
        }

        &:focus + .slider {
          box-shadow: 0 0 1px $intel;
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $dark_gray;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 20px;

        &:before {
          position: absolute;
          content: "";
          height: 25px;
          width: 25px;
          left: 0px;
          bottom: -9px;
          background-color: $gray_light_2;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
          border: 2px solid $dark_gray;
        }
      }

    }
  }

  input[type="submit"],
  input[type="button"],
  button {
    text-decoration: none;
    letter-spacing: 0px;
    font-family: $cl_intel_font-family;
    padding: 10px;
    width: auto;
    height: auto;
    color: $white;
    background-color: $intel;
    border: none;
    min-width: 128px;
    min-height: 48px;

    &:hover {
      background-color: $button_blue_hover;
    }

    &:disabled {
      color: $dark_gray;
      background-color: $gray;
    }
  }

  .form-item {
    margin: 0 0 25px 0;

    &:disabled,
    &.disabled {
      opacity: 0.5;
      cursor: none;
    }

    &.form-type-select {
      &:before {
        content: '\f0dd';
        color: $intel;
        font-family: FontAwesome;
        font-size: 16px;
        position: relative;
        left: 402px;
        top: 35px;
        margin-left: -12px;
        z-index: 1;
        pointer-events: none; 
      }
    }

    &.form-type-select.numeric {
      &:before {
        content: '\f0dc';
        color: $intel;
        font-family: FontAwesome;
        font-size: 16px;
        position: relative;
        left: 402px;
        top: 38px;
        margin-left: -12px;
        z-index: 1;
        pointer-events: none; 
      }
    }

    &.form-type-radio, &.form-type-checkbox {
      margin: 10px 0;

      label {
        font-family: $cl_intel_font-family;
        font-size: 16px;
        font-weight: normal;
        color: $dark_gray;
        line-height: 18px;
        position: relative;
        top: -20px;
        left: 10px;
      }
    }

    .description {
      font-family: $cl_intel_font-family;
      font-size: 0.75em;
      color: $button;
      letter-spacing: 0.55px;
      line-height: 0.875em;
      margin-top: 5px;
    }
  }

  &.user-login-form, &.user-pass {
    max-width: 90%;
    margin: 25px auto;

    @media only screen and (min-width: $cl_xl) {
      @include container();
    }

    p {
      margin: 0 0 25px 0;
      font-family: $cl_intel_font-family;
      font-size: 0.75em;
      color: $button;
      letter-spacing: 0.55px;
      line-height: 0.875em;
      margin-top: 5px;
    }
  }
}

.toolbar__container {
  form {
    margin: 0;
  }

  .form--inline {
    .form-item {
      &:last-child {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-left: 25px;

        &:first-child {
          margin-left: 0;
        }
      }

      .error-message {
        display: none;
      }

      &.form-type-select {
        float: none;

        label {
          margin-left: 5px;
          top: 12px;
        }

        select {
          margin-top: 0;
          margin-bottom: 0;
          background: rgba($gray_light_2, 0.5);
          color: $white;

          width: auto;
          max-width: 100%;
          padding-right: 35px;
        }
        &:before {
          color: $white;
          left: 96%;
          top: 6px;
        }
      }

      &.form-type-select.numeric {
        &:before {
          left: 98%;
          top: 18px;
        }
      }
    }
  }
}
