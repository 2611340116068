// Community counter
//
// Components Community counter
//
// Markup: community_counter.twig
//
// Style guide: components.community_counter

.community_counter__list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {

    b {
      color: $tab-purple-active;
    }
  }
}