// Two columns
//
// Components Two columns
//
// Markup: two_columns.twig
//
// Style guide: components.two_columns
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.Column {
  width: 100%;

  @media only screen and (min-width: $cl_md) {
    display: flex;
    justify-content: space-between;

    .field__item {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    display: block;
    width: 100%;
    box-sizing: border-box;
    
    .section {
      
      &.section-two {

        .field__items {
          display: flex;
          flex-direction: column;

          @media only screen and (min-width: $cl_md) {
            flex-direction: row;
            justify-content: space-between;
          }

          .field__item {
            width: 100%;

            @media only screen and (min-width: $cl_md) {
              width: calc(100% * 1/2 - 10px);
            }
          }
        }
      }
    }

    @media only screen and (min-width: $cl_md) {
      display: flex;
      flex-direction: column;
      width: calc(100% * 1/2 - 10px);

      &.column__first {
        margin-left: 0;
      }

      &.column__second {
        margin-right: 0;
      }

      .Column.column_left_main &,
      .Column.column_right_main & {
        width: calc(100% * 1/3 - 10px);
      }

      .Column.column_left_main & {
        &:first-child {
          width: calc(100% * 2/3 - 10px);
        }
      }

      .Column.column_right_main & {
        &:nth-child(2) {
          width: calc(100% * 2/3 - 10px);
        }
      }
    }
    img {
      width: 100%;
      min-height: auto;
      float: left;
      padding: 15px 0;
    }
    .fa {
      font-size: 200px;
    }
  }

  .column__icon {
    text-align: center;
  }
}

.alias--downloads,
.alias--stacks {

  .padding--top-bottom {

    &.two_column {
      padding-top: 40px;
      padding-bottom: 0;

      .column__second {
        padding-top: 40px;
        padding-bottom: 40px;

         @media only screen and (min-width: $cl_md) {
          padding-top: 0;
        }
      }
    }
  }
}