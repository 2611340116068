.Nav {
  &__controls,
  &__tabs,
  &__breadcrumbs {
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      width: auto;
      a {
        width: auto;
        text-decoration: none;
      }
    }
  }
  &__controls {
    li {
      display: table-caption;
      margin: 20px 0;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      border: 0;
      background-color: $controls;
      &:hover,
      &.active {
        background-color: $pantone_cyan;
        cursor: auto;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__tabs {
    li {
      width: 100%;
      @media only screen and (min-width: $cl_md) {
        width: auto;
      }
      a {
        display: block;
        padding: 29px;
        border-width: 0 0 3px 0;
        border-color: transparent;
        border-style: solid;
        color: $gastly;
        letter-spacing: 2.25px;
        font-size: 1.125em;
        text-align: center;
      }
      &.active,
      &:hover {
        a {
          border-color: $intel;
          color: $dark;
        }
      }
    }
  }
  &__breadcrumbs{
    li {
      &::after {
        content: "/";
        margin: 0 10px;
      }
      &:last-child {
        &::after {
          content: "";
        }
        a {
          cursor: default;
        }
      }
      a {
        color: $dark;
        font-size: 0.9375em;
        letter-spacing:0.19px;
        line-height:30px;
      }
    }
  }
}
