@import "../../../../bower_components/susy/sass/susy";

#bundle {
  .bundle__tabs {
    @include container(80% center static);

    .tabs__button {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
        color: $black_light;
        background-color: #f7f8f9;
        border-bottom: 4px solid transparent;
        font-weight: 100;
        line-height: 18px;
        margin: 3px 0;
        padding: 20px 40px;
        text-transform: uppercase;

        &:hover {
          cursor: pointer;
        }
      }

      .active {
        background-color: #f1f3f5;
        margin: 0;
      }
    }

    ul.tabs__button li:first-child {
      margin-right: 3px !important;
    }
  }

  .tab__content {
    padding: 45px 40px;
    text-align: left;

    h4 {
      font-size: 18px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        color: $gray_alt;
        margin-bottom: 5px;

        a {
          color: $pantone_cyan;
          border-bottom: none;
        }
      }
    }

    p {
      color: $black_light;
      font-size: 16px;
      margin-bottom: 5px;

      a {
        color: $pantone_cyan;
        font-weight: bold;
      }
    }

    .bundles-commands {
      font-family: $cl_intel_font-family-light;
      margin: 30px 0 60px;

      p {
        span {
          color: $black_light;
          font-weight: bold;
          display: block;
        }

        code {
          color: $gray_alt;
          background-color: #c6ebdc;
          display: inline-block;
          padding: 5px 20px;
          margin: 15px 0;
          position: relative;

          &:before {
            content: '\f0c5';
            font: normal normal normal 16px/1 FontAwesome;
            font-weight: bold;
            margin-right: 15px;
            color: $gray_alt;
          }

          span {
            background-color: #296d88;
            color: $white;
            font-size: 13px;
            font-weight: 100;
            position: absolute;
            padding: 5px;
            bottom: -35px;
            left: 30px;

            &:before {
              content: '';
              position: absolute;
              top: -10px;
              left: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 0 0 10px;
              border-color: transparent transparent transparent #296d88;
            }
          }

          &:hover {
            cursor: pointer
          }
        }
      }
    }
  }
}

#get__bundle {
  background-color: #f1f3f5;
}

#inside__bundle {
  background-color: #f1f3f5;
}

@media only screen and (max-width: $cl_sm) {
  #bundle {
    .bundle__tabs {
      width: 100%;
      margin: 0;

      .tabs__button li {
        padding: 20px;
      }
    }

    .tab__content {
      padding: 45px 35px;
    }
  }
}
