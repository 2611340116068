// Table
//
// Components Table
//
// Markup: table.twig
//
// .zebra - Style 2- Zebra
// .column-to-highlight - Style 3- Emphasis column to highlight term
//
// Style guide: components.table

table {
  background: $white;
  border: none;
  border-collapse: separate;
  width: 100%;
  margin: 35px 0;
  width: 100%;
  min-width: 450px;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  .alias--developer & {
    border-spacing: 0 $padding_small;

    td {
      vertical-align: top;
    }
  }

  caption {
    font-size: $cl_font-size - 2;
    font-family: $cl_intel_font-family-bold;
  }

  td, th {
    border-bottom: solid 1px $grayish;
    font-family: $cl_intel_font-family;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    height: 2.5em;
    color: $charcol;
  }
  thead, tr:first-child {
    background-color: $white;
    th {
      border-bottom: solid 2px $pantone_cyan;
      font-family: $cl_intel_font-family-bold;
    }
  }
  &.zebra {
    tbody tr:nth-child(odd) {
       background-color: $light_blue_gray;
    }
  }
  &.column-to-highlight {
    tbody tr {
      td:first-child {
        font-family: $cl_intel_font-family-bold;
        background-color: $light_blue_gray;
      }
    }
  }

  &.footnote {
    border-collapse: collapse;
    margin: 0;

    td {
      font-size: $cl_font-size - 1;
      padding: 0 0.5em;
      height: auto;
      border-bottom: none;
    }
  }
}

table {
  margin: 1em 0;
  min-width: 500px; 

  th, td {
    text-align: left;
    display: table-cell;
    padding: .25em .5em;
    
    &:first-child {
      padding-left: 0;
    }
    
    &:last-child {
      padding-right: 0;
    }
  }
}

table {
  border: none;
  overflow: hidden;
  tr {
    border-color: lighten(#34495E, 10%);
  }
}

.responsive-table {
  overflow-x: auto;
}