// Call to action
//
// Component Call to action
//
// Markup: call_to_action.twig
//
// Style guide: components.call_to_action
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.call_to_action {
  box-sizing: border-box;
  display: inline-block;
  font-family: $cl_intel_font-family-bold;
  font-size: $fsz-button;
  text-transform: uppercase;
  letter-spacing: 0.16px;
  text-align: center;
  padding: 7px 20px;
  color: $white!important;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  background: $blue;
  line-height: $fsz-button;

  .alias--announcements &,
  .alias--blogs-news &,
  .alias--news-blogs {
    background: $teal;

    &:hover,
    &:active,
    &:focus {
      background: $teal-dark;
    }
  }

  &.Signature {
    background-color: transparent;
    border: none;
    color: $blue !important;
    padding-right: 10px;

    .fa {
      color: $blue;
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $intel !important;
      text-decoration: underline;

      .fa {
        color: $intel;
      }
    }
  }

  &.SHA512 {
    background-color: transparent;
    border: none;
    color: $blue !important;
    padding-right: 10px;

    .fa {
      color: $blue;
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $intel !important;
      text-decoration: underline;

      .fa {
        color: $intel;
      }
    }
  }

  .fa {
    float: left;
    font-size: $fsz-button !important;
    line-height: $fsz-button;
    margin-right: 10px;
    margin-left: 0;
  }

   &:disabled {
    background-color: $gray;
    color: $dark_gray;
    border: 2px solid $gray;
  }

  &_il {
    .fa {
      float: left;
      margin-right: 15px;
      margin-left: 0;
    }
  }

  &_ir {
    .fa {
      float: right;
      margin-left: 15px;
      margin-right: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    border:  none;
    background: $blue-dark;
    text-decoration: none;
    outline: none;
  }

  &.button__color_1 {
    background: $text;

    &:hover,
    &:active,
    &:focus {
      background: #000;
    }
  }

  &.button__color_3 {
    background: $teal;

    &:hover,
    &:active,
    &:focus {
      background: $teal-dark;
    }
  }

  &.button__color_2 {
    background: $purple;

    &:hover,
    &:active,
    &:focus {
      background: $purple-dark;
    }
  }
}

%bg-gradient--blue {
  .call_to_action__secondary {
    border-color: $white;
    background-color: transparent;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: $pantone_cyan;
      color: $white;
      border-color: $pantone_cyan;
    }
  }
}

.feature {
  .call_to_action {
    &__secondary {
      margin-top: $comp_space_md;

      &:hover,
      &:active,
      &:focus {
        background-color: $intel;
        color: $white;
        border-color: $intel;
      }
    }
  }
}

.Text {
  .call_to_action {
    &__secondary {
      margin-top: $comp_space_md;
    }
  }
}
