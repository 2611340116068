// Search Layout
//
// Layout Search
//
// Markup: search.twig
//
// Style guide: layout.search
#views-exposed-form-landing-pages-search-blog {
	margin-left: 0;

	.form-item-search {
		display: inline-block;
		width: 80%;

		@media only screen and (min-width: $cl_lg) {
			width: 90%;
		}

		input[type=text] {
			width: 100%;
			max-width: 100%;
		}
	}

	.button {
		margin-top: 8px;
		min-height: 40px;
		min-width: 100%;
	}

	.form-actions {
		display: inline-block;
	}
}