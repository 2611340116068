// Secondary link
//
// Components Secondary link
//
// Markup: secondary_link.twig
//
// Style guide: components.secondary_link
.secondary_link__primary,
.secondary_link__secondary {
  margin: 20px 0 0 0;
  font-family: $cl_intel_font-family-bold;
  font-size: 14px;
  display: block;
  line-height: 18px;
  letter-spacing:0.12px;

  a {
    color: inherit;

     &:hover {
       text-decoration: underline;
     }
  }
}

.secondary_link__primary {
  color: $intel;
}

.secondary_link__secondary {
  color: $white;
}
