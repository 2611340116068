// Card
//
// Components Card
//
// Markup: card.twig
//
// Style guide: components.card
.card {
  position: relative;
  background: transparent;
  padding: $padding_card;
  margin: 0 auto $padding_horizontal auto;
  width: 100%;
  box-sizing: border-box;
  flex: auto;
  font-size: $fsz-tile-body;
  transition: all 0.2s ease-in-out;

  &.bg_color_1 {
    background: $blue-light;
  }

  &.bg_color_2 {
    background: $gray;
  }
  
  &.bg_color_3 {
    background: $purple-light;
  }
  
  &.bg_color_4 {
    background: $teal-light;
  }
  
  &.bg_color_5 {
    background: $white;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $padding_small;

    h2 {
      font-family: $cl_intel_font-family-bold;
      font-size: $fsz-tile-heading;
      line-height: $lh-tile-heading;
      letter-spacing: 0;
    }

    div {

      .double & {
        margin-top: -8px;
        width: calc(100% * 1/2 - 5px);
      }

      &:nth-child(2) {
        a {
          margin-left: auto;
        }
      }

      .call_to_action {
        margin-right: -20px;
      }
    }

  }

  &__body {
    overflow-x: auto;

    table {
      min-width: 100%;
    }

    p {

      &:first-child {
        margin-top: 0;
      }
    }

    .field__item {
      width: 100%!important;
    }

    .menu {
      list-style: none;
      padding-left: 0;

      li {
        a {
          color: $text;
        }
      }
    }

    &.columns {

      .card-dynamic-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media only screen and (min-width: $cl_sm) {
          flex-direction: row;
        }

        .card__dynamic_item  {
          width: 100%;

          @media only screen and (min-width: $cl_sm) {
            max-width: 100%;
            width: calc(100% * 1/2 - 20px);
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    table {

      &.content-table {
        width: 880px;

        @media only screen and (min-width: $cl_md) {
          width: 100%;
        }
      }

      td {
        text-align: center;
        display: table-cell;
        padding: 0 10px;
      }

      img {
        width: auto;
        display: block;
        margin: 0 auto;
        float: none;
      }
    }

    article {

      img {
        float: none;
      }
    }
  }

  &__footer {
    margin-top: $padding_small;
    position: relative;

    @media only screen and (min-width: $cl_md) {
      &__grid {
        display: flex;

        > div {
          flex: 50%;
        }

        .card__footer__left {
          position: relative;
          border-right: 1px solid $blue;
        }

        .buttons__lts {
          margin-top: 0;
          padding-left: 1rem;
        }
      }
    }

    &--links {
      display: block;
      width: 100%; 

      .link {
        margin: 0;
      }
    }
    
    a {
      margin: 5px;
    }
  }

  table {
    
    tbody {

      tr {

        td {
          border-bottom: none;
          height: auto;

          &::before {
            content: '';
            display: none;
          }
        }
      }
    }
  }
}

.card__group {
  .field--name-field-feature {
    display: none!important;
  }
}

@media only screen and (max-width: $cl_md) {
  article[about="/downloads"] {
    &.node--type-landing-page {
      &.node--view-mode-full {
        section {
          &.padding--top-bottom {
            &.card_group {
              padding-bottom: 0;
            }

            &.two_column {
              padding-top: 0;

              .section {
                &.card__group {
                  .card {
                    padding-bottom: 0;
                    padding-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.featured__cards {
  .field--name-field-cards {
    &.field__items {
      margin: 10px;

      @media only screen and (min-width: $cl_sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
  
      > .field__item {
        margin: 10px;
    
        @media only screen and (min-width: $cl_sm) {
          max-width: calc(100% * 1/2 - 20px);
          width: calc(100% * 1/2- 20px);
        }
      }
    }
  }

  .card {
    height: 100%;
    margin-bottom: 0;
    
    &.bg_color_1 {
      background: $blue-light;
    }
  
    &.bg_color_2 {
      background: $gray;
    }

    &.bg_color_3 {
      background: $purple-light;
    }

    &.bg_color_4 {
      background: $teal-light; 
    }

    table {
    
      tbody {
  
        tr {
  
          td {
            border-bottom: none;
            height: auto;

            &::before {
              content: '';
              display: none;
            }
          }
        }
      }
    }
  }
}

.alias--downloads {

  .padding--top-bottom {
    padding-top: 0;
    padding-bottom: 0;

    .card {
      padding-top: 40px;
      margin-bottom: 0;
    }
  }
  
  .column__second {

    .card {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .featured__cards {
    padding-top: 40px;
    
    @media only screen and (min-width: $cl_sm) {
      padding-bottom: 0;
      padding-top: 0;
    }

    .field--name-field-cards {
      
      &.field__items {

        @media only screen and (min-width: $cl_sm) {
          margin: 0;
          margin-top: 40px;
        }

        > .field__item {
      
          @media only screen and (min-width: $cl_sm) {
            max-width: calc(100% * 1/3 - 20px);
            width: calc(100% * 1/3 - 20px);
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
