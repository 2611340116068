#bundle {
  .bundle__container {
    background-color: $white;
    padding: 32px;

    .bundle__content {
      align-items: center;
    }

    .row {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .column__left,
    .column__right {
      box-sizing: border-box;
      flex: 0 0 auto;
    }

    .column__left {
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: space-between;
    }

    .column__right {
      .links__wrapper {
        .field__item a {
          white-space: nowrap;
        }
      }
    }

    .media {
      display: flex;
      align-items: flex-start;

      img {
        width: 70px;
        height: 70px;
      }
    }

    .bundle__info__wrapper {
      margin-left: 35px;
      padding-right: 30px;
      max-width: 100%;
      text-align: left;

      h2 {
        font-weight: bold;
        margin-top: 0;
      }

      .category__wrapper {
        .field__item:not(:last-child):after {
          content: ',';
        }
      }

      .source__wrapper {
        margin: 10px 0;
        color: $text-light;
        font-size: 16px;
      }
    }

    .description__wrapper {
      margin-top: 10px;

      p {
        margin-bottom: 5px;
        margin-top: 5px !important;
        text-align: left;
      }
    }

    .category__wrapper {
      margin-top: 30px;

      div {
        margin-bottom: 5px;
        margin-right: 5px;
        text-align: left;
      }

      .field__item {
        display: block;
        float: left;
        width: auto;
        color: $text-light;
        text-transform: uppercase;
      }
    }

    .category__wrapper > div {
      display: block;
    }
  }
}

@media only screen and (max-width: $cl_lg) {
  #bundle {
    .bundle__container {
      .bundle__info__wrapper {
        margin-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: $cl_sm) {
  #bundle {
    .bundle__container {
      .column__left {
        display: block;
        flex-basis: 100%;
        max-width: 100%;
        margin: 10px 0;
      }

      .column__right {
        flex-basis: 100%;
        max-width: 100%;
        margin-top: 20px;
      }

      .links__wrapper {
        display: flex;
        justify-content: start;

        .field__item:first-child a {
          border-bottom: 1px solid #F0F2F4;
        }
      }
    }
  }
}

@media only screen and (max-width: $cl_mobile) {
  #bundle {
    .bundle__container {
      padding: 32px 15px;

      .column__right {

        .links__wrapper {

          .field__item a {
            white-space: normal;
            display: flex;
            align-items: baseline;
          }
        }
      }
    }
  }
}
