// Search Input
//
// Components Search Input
//
// Markup: search_input.twig
//
// .full_width - Search input full width
//
// Style guide: components.search_input
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.header__search_form__wrapper {
  display: block;

  .header__search_form {
    position: relative;
    float: right;
    display: none;

    &.visible {
      display: inline-block;
      float: right;
      margin: -10px 0 0 20px;
    }

    i {
      position: absolute;
      top: 17px;
      right: auto;
      left: 5px;
      color: $white;
      transform: translateY(-50%);
      font-size: $lh-tile-body;
    }

    input[type=text] {
      font-family: $cl_intel_font-family;
      color: $white;
      padding-left: 40px;
      padding-right: 25px;
      border-radius: 50px;
      background: #4c5155;
      max-height: 34px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    input[type=text]::placeholder {
      color: $white;
    }

    input[type=text]::-webkit-input-placeholder {
      color: $white;
    }

    input[type=text]:-ms-input-placeholder {
      color: $white;
    }

    input[type=text]::-ms-input-placeholder {
      color: $white;
    }

    input[type=text]:-moz-placeholder {
      color: $white;
    }

    input[type=submit] {
      display: none;
    }

    &.full_width {
      display: block;
      margin-bottom: $comp_space_md;
      float: none;

      input[type=text] {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.header__search_cancel__wrapper {
  float: left;

  .header__search_cancel {
    font-family: $cl_intel_font-family;
    font-size: 16px;
    color: $dark_gray;
    line-height: 18px;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    text-align: left;
    display: none;

    &:hover {
      color: $intel;
      font-weight: bold;
    }
  }
}

