#software {
  .bundles__category__list {
    margin-top: 0;

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        display: flex;
      }

      li:first-child {
        padding-left: 20px;
      }

      a {
        display: inline;
        border-bottom: none;
        color: $charcol;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        padding: 2px 4px;
      }

      .active {
        color: $black_light;
        font-weight: bold;
      }

      img {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: sub;
      }

      .active__category {
        text-decoration: underline;
      }
    }
  }

  .show-list, .hide-list {
    display: none;
  }
}

// Temporary style
#edit-field-bundle-category {
  display: none;
}

// TODO: Will be upgrade.
@media only screen and (max-width: $cl_sm) {
  #software {
    .bundles__category__list {
      background: $white;

      ul {
        margin: 0 0 15px 0;
        padding: 0;
      }

      li {
        display: block;
        padding: 10px;
        margin: 0;
      }
    }

    .bundles__category__list li:last-child {
      border-bottom: transparent;
    }

    .show-list {
      display: block;
      position: absolute;
      right: 10px;
      color: $github-color;
      border: 0;
      font-size: 26px;
      line-height: 40px;
      cursor: pointer;
      transition: all ease 0.8s;
      z-index: 2;
    }

    .mobile-category {
      display: inline-block;
    }
  }

  .arrow {
    transform: rotate(180deg);
    transition: all ease 0.5s;
  }
}
