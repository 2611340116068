// Back to top
//
// Components Back to top
//
// Markup: back_to_top.twig
//
// Style guide: components.back_to_top

.back_to_top {
  opacity: 0.4;
  z-index: 999;
  cursor: pointer;
  position: fixed;
  bottom: 90px;
  right: 20px;
  text-decoration: none;
  color: #051b2c;
  font-size: $fsz-tile-body;
  font-weight: bold;
  display: block;
  text-align: center;

  @media only screen and (min-width: $cl_xl) {
    right: calc((100% - 1030px) / 2);
  }

  &.overlapped {
    bottom: 320px;
  }

  &:hover {
    opacity: 1;
  }

  i {
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: rgba(0,0,0,0.3);
    border-radius: 50%;
    text-align: center;
    color: $text;

    &.fa {
      font-size: $fsz-tile-heading;
      font-weight: bold;
    }


    &:before {
      position: absolute;
      top: 40%;
      left: 50%;
      -webkit-transform: translateY(-50%) translateX(-50%);
      -moz-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
      -o-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
    }
  }

  span {
    display: block;
  }

}
