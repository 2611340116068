// Page Header
//
// Layout Page Header
//
// Markup: page_header.twig
//
// Style guide: layout.page_header
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.page_header {
  width: 100%;
  background-image: linear-gradient(-64deg, #064f8b 0%, #006ec8 67%, #3cc6e5 100%);

  .page_header__title {
    font-family: $cl_intel_font-family;
    font-weight: lighter;
    font-size: 24px;
    line-height: 30px;
    color: $white;
  }
}

@media only screen and (min-width: $cl_lg) {
  .page_header {
    .page_header__title {
      font-size: 36px;
      letter-spacing: 0.36px;
      line-height: 45px;
      text-align: left;
      margin-top: $comp_space_md;
    }
  }
}
