// Community card
//
// Components Community card
//
// Markup: community.twig
//
// Style guide: components.community
.Community {
  width: 100%;
  background-image:linear-gradient(-181deg, #eeeeee 0%, #ffffff 29%, #e3e9fe 100%);
  overflow: hidden;
  &__item {
    height: auto;
    padding: 15px 0;
    display: block;
    text-align: center;
    @include span(24 of 24);
  }
  &__ico {
    margin: 20px auto 0 auto !important;
    text-align: center;
    color: $dark;
    font-size: 4em!important;
    display: block!important;
  }
  &__title {
    font-family: $cl_poppins_font-family-medium;
    font-size: 0.9375em;
    color: $dark;
    letter-spacing: 0.19px;
    line-height: 1.875em;
    text-align: center;
    margin-top: 17px;
  }
  &__description {
    font-family: $cl_intel_font-family;
    color: $charcol;
    letter-spacing: 0.16px;
    line-height: 2em;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
  }
  &__link {
    margin: 45px auto 0 auto;
    display: inline-block;
  }
}
