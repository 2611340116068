// Footer
//
// Layout Footer
//
// Markup: footer.twig
//
// Style guide: layout.footer
.footer {
  background: url(../sass/components/layout/footer/assets/banner.bg.landing.svg) no-repeat;
  background-size: cover;
  font-family: $cl_intel_font-family;
  color: $white;
  font-size: $fsz-tile-body;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__logo, &__details {
    width: 100%;

    @media only screen and (min-width: $cl_sm) {
      width: calc(100% * 1/2 - 20px);
      margin: 0 10px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    &__wrapper {
      display: flex;
      align-items: flex-end;
    }

    .footer__site_img_object {
      height: 79px;
      margin-right: 5px;
    }

    .footer__site_txt_object {
      height: 40px;
    }
  }

  .footer__menu__list_item,
  .footer__social_media__list_item {
    margin-bottom: 0;

    a {
      color: $white;
      font-size: $fsz-tile-body;

      .fa-discourse {

        &::before {
          content: '';
          background: url(../sass/components/layout/footer/assets/discourse.png) no-repeat;
          background-size: 64px;
          background-position: 0 0;
          width: 32px;
          height: 32px;
          float: left;
        }
      }
    }

    img {
      max-width: calc(100% - 20px);
      width: 448px;
      padding: 0 15px;
    }
  }

  &__details {

    .footer__top, .footer__bottom {
      display: block;
      width: 100%;

      hr {
        background: $white;
        border-top: 0 solid $white;
      }

      .footer__menu,

      .footer__social_media {

        .footer__social_media__list {
          list-style: none;

          .footer__menu__list_item,

          .footer__social_media__list_item {
            a {
              color: $white;
              font-size: $fsz-main-feature-heading;
            }

            a:hover {

              .fa-facebook-official {
                color: $facebook-color;
              }

              .fa-twitter {
                color: $twitter-color;
              }

              .fa-google-plus, 
              .fa-google-plus-square {
                color: $google-plus-color;
              }

              .fa-pinterest {
                color: $pinterest-color;
              }

              .fa-tumblr {
                color: $tumblr-color;
              }

              .fa-reddit {
                color: $reddit-color;
              }

              .fa-snapchat {
                color: $snapchat-color;
              }

              .fa-flickr {
                color: $google-plus-color;
              }

              .fa-instagram {
                color: $instagram-color;
              }

              .fa-linkedin-square {
                color: $linkedin-color;
              }

              .fa-youtube-play {
                color: $youtube-color;
              }

              .fa-github,
              .fa-github-square {
                color: $github-color;
              }

              .fa-discourse,
              .discourse {
                &::before {
                  background-position: -33px 0;
                }
              }
            }
          }

          .footer__social_media__list_item {
            padding-left: 0px;
            padding-right: 30px;

            &:last-child {
              padding-right: 0;
            }

            a {
              font-size: 32px;
            }
          }
        }
      }
    }
  }

  .footer__menu {
    width: 100%;

    &__list {
      display: inline-flex;
      list-style: none;

      @media only screen and (min-width: $cl_sm) {
        display: block;
      }

      @media only screen and (min-width: $cl_md) {
        display: inline-flex;
      }

      &_item {
        padding: 5px;
        margin-right: $padding_medium;
        margin-bottom: 0;

        a {
          color: $white;
        }

        &:hover {

          a {
            color: $blue-light;
          }
        }
        
      }
    }
  }

  .footer__social_media {
    width: 100%;
    text-align: left;
    margin-top: 30px;

    &__list {
       display: inline-flex;

       &_item {

        &:hover {

          a {
              color: $blue-light;
            }
          }
       }
    }


    a:hover {
      .fa-facebook-official {
        color: $facebook-color;
      }

      .fa-twitter-square {
        color: $twitter-color;
      }
    }
  }

  .footer__bottom {
    margin-top: 15px;
  }

  .footer_bottom {
    background: $text;
    color: $white;

    &__copyright {
      width: 100%;
      padding: 15px 0 15px 30px;
      font-size: $fsz-app-tile-aditional;
      position: relative;

      i {
        font-weight: 400;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: $fsz-tile-heading;
      }
    }
  }

}

@media only screen and (min-width: 0px) and (max-width: $cl_mobile) {
  .footer__menu__list {
    display: block !important;
  }
}

@media only screen and (min-width: $cl_lg) {
  .footer {
    .footer__top, .footer__bottom {
      width: 100%;

      .footer__menu {
        width: 100%;
        text-align: left;
      }

      .footer__social_media {
        width: 100%;
        margin-top: 0;

        .footer__social_media__list_item {
          padding-left: 5px;
          padding-right: 0px;
        }
      }
    }
  }
}