.Tasks {
  width: 100%;
  min-height: 85px;
  height: auto;
  background: rgba(241, 243, 248, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    width: auto;
    li {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 20px 0;
      @media only screen and (min-width: $cl_sm) {
        width: auto;
        margin: 0 20px;
      }
      a{
        color: $charcol;
        font-family: $cl_poppins_font-family-medium;
        font-size: 1.5em;
        &:hover {
          color: $dark;
        }
      }
    }
  }
}
