// Leading text
//
// Components Leading text
//
// Markup: leading_text.twig
//
// Style guide: components.leading_text
.Leading {
  position: relative;
  display: block;
  text-align: center;
  font-size: 16px;
  &__title {
    font-family: $cl_intel_font-family-light;
    font-weight: 300;
    font-size: 1.875em;
    line-height: 1.23em;
    margin-bottom: 32px;
    color: $button;
  }
  &__body {
    p {
      font-family: $cl_intel_font-family-light;
      font-weight: 300;
      font-size: 1.25em;
      color: $charcol;
      line-height: 1.46em;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        color: $intel;
        font-family: $cl_intel_font-family-bold;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
