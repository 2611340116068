// Hero carousel
//
// Components Hero carousel
//
// Markup: hero_carousel.twig
//
// Style guide: components.hero_carousel
.Carousel {
  width: 100%;
}

.navCarousel {
  display: block;
  text-align: center;
  height: 32px;
  margin-top: -36px;
  z-index: 2;
  position: relative;

  button {
    display: inline-block;
  }

  > div {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  .owl-custom-dots {
    float: left;
    height: 25px;
    background-color: rgba(0,0,0, 0.5);
    display: inline-block;
    padding: 0 5px;
    margin: 0 1px;

    .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: $white;
      margin: 0 5px;
      position: relative;
      top: 2px;
      cursor: pointer;

      &.active {
        background: $pantone_cyan;
      }
    }
  }

  .btnCarousel {
    font-family: FontAwesome;
    border: none;
    padding: 0;
    width: 25px;
    height: 25px;
    float: left;
    background-color: rgba(0,0,0,0.5);

    &:focus, &:visited {
      outline: none;
    }

    &:before {
      color: $white;
      padding: 7px 9px;
      font-size: 10px;
    }

    &.customPrevBtn {
      color: transparent;

      &:before {
        content: '\f053';
        color: $white;
      }
    }

    &.customNextBtn {
      color: transparent;

      &:before {
        content: '\f054';
        color: $white;
      }
    }
  }
}

.owl-carousel {
    .owl-stage {
      display: flex;
    }
    
    .owl-item {
      display: flex;
      flex: 1 0 auto;

      .banner {
        width: 100%;
      }
    }
    
    .caption {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }
    
    .flex-text {
      flex-grow: 1
    }
    
    .thumbnail {
      display: flex;
      flex-direction: column;
      margin: 0 15px;
    }

    .banner__support_img {
      display: none;

      @media only screen and (min-width: $cl_sm) {
        display: flex;
      }
    }
  }
