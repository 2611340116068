.Title {
  width: 100%;
  margin: 25px 0 0 0;
  font-size: 16px;
  h1 {
    font-family: $cl_poppins_font-family-SemiBold;
    font-size: 1.5em;
    color: $dark;
    letter-spacing: 0.21px;
    line-height: 32px;
    text-align: left;
  }
}
