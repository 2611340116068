// Social Channel List
//
// Component Social Channel List
//
// Markup: social_channel_list.twig
//
// Style guide: components.social_channel_list
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.social_channel_list {
  width: 100%;

  .social_channel_list__items {
    list-style-type: none;
    margin: $comp_space_md 0;
    padding: 0;
    display: flow-root;

    .social_channel_list__item {
      float: none;
      width: 100%;
      margin: 0;
    }
  }

  .social_channel_list__call_to_action {
    text-align: center;
    margin-bottom: $comp_space_md;
  }
}

@media only screen and (min-width: $cl_sm) {
  .social_channel_list {
    .social_channel_list__items {
      .social_channel_list__item {
        float: left;
        width: 48%;
        margin: 0 1%;

        .social_channel {
          height: 170px;
          min-height: 170px;
        }
      }
    }
  }
}
