// Toolbar
//
// Layout Toolbar
//
// Markup: toolbar.twig
//
// Style guide: layout.toolbar

.header__menu-submenu {

  .toolbar__container {
    width: 100%;
    background: $black;
  
    span {
      font-family: $cl_intel_font-family;
      font-size: 14px;
      color:$white;
      letter-spacing:0;
      line-height:27px;
      text-align:left;
  
      &.toolbar__date {
        &:after {
          content: "|";
          font-weight: bold;
          padding: 0 10px;
        }
      }
  
      &.toolbar__categories {
        font-family: $cl_intel_font-family-bold;
        color: $white;
      }
  
      &.toolbar__author {
        font-family: $cl_intel_font-family;
        font-size: 14px;
        color: $white;
      }
    }
  
    .toolbar__categories {
      font-family: $cl_intel_font-family-bold;
      a {
        color: $white;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    a {
      &.toolbar__author {
        font-family: $cl_intel_font-family;
        font-size: 14px;
        color: $white;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    .main-menu,
    .Header__main {
      margin: 0;
      padding: 15px 0 0 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      padding-top: 15px;
  
      li {
        display: block;
        height: 64px;
        margin: 0 2px;
        background-color: transparent;
        width: auto;
  
        &.is-active,
        &:hover {
          background-color: $text;
        }
  
        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 20px 15px;
          text-align: center;
          box-sizing: border-box;
          color: $white;
          text-decoration: none;
          font-size: 9px;
          text-transform: uppercase;
          line-height: 9px;

          @media only screen and (min-width: $cl_mobile) {
            font-size: $fsz-tab-text;
            line-height: $fsz-tab-text;
            padding: 20px 30px;
          }
  
          &:hover,
          &.is-active {
            text-decoration: none;
            background-color: $text;
          }
        }
      }
    }
  }
  
  &.blue {
    .toolbar__container {
      background: $blue-dark;

      .main-menu,
      .Header__main {

        li {

          &.is-active,
          &:hover {
            background-color: $blue;
          }

          a {

            &:hover,
            &.is-active {
              background-color: $blue;
            }
          }
        }
      }
    }
  }

  &.green {
    .toolbar__container {
      background: $teal-dark;

      .main-menu,
      .Header__main {

        li {

          &.is-active,
          &:hover {
            background-color: $tab-green-active;
          }

          a {

            &:hover,
            &.is-active {
              background-color: $tab-green-active;
            }
          }
        }
      }
    }
  }

  &.purple {
    .toolbar__container {
      background: $purple-dark;

      .main-menu,
      .Header__main {

        li {

          &.is-active,
          &:hover {
            background-color: $tab-purple-active;
          }

          a {

            &:hover,
            &.is-active {
              background-color: $tab-purple-active;
            }
          }
        }
      }
    }
  }
}


.alias--search {
  
  .toolbar__container {
    display: none!important;
  }
}