// Highlights carousel
//
// Components Highlights carousel
//
// Markup: highlights_carousel.twig
//
// .dynamic_block.features - Style 2 - Blue container with white text
//
// Style guide: components.highlights_carousel

.highlights_carousel__wrapper {
  font-family: $cl_intel_font-family;
  text-align: center;
  width: 100%;

  .highlights_carousel {
    border-bottom: 0;
  }

  .highlights_carousel__title {
    color: $intel;
    text-transform: uppercase;
    font-family: inherit;
  }

  .highlights_carousel__container {
    padding: 64px 0 32px 0;
  }

  .highlights_carousel {
    .highlight {
      @include span(8);
      float: none;
      margin: 0 auto;
    }
    .owl-item {
      .highlight {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
      }
    }
    .owl-nav {
      position: relative;
      top: 44px;

      .owl-prev,
      .owl-next {
        &::before {
          background-color: $white;
          color: $charcol;
          border: solid 1px $gray_light_2;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 100%;
          padding: 8px 8px 6px 8px;
          font-size: 14px;
        }
      }

      .owl-prev {
        left: 44%;
      }

      .owl-next {
        right: 45.2%;
      }
    }

    .owl-dots {
      height: 32px;
      max-width: 26%;
      background-color: rgba($gray, 0.5);
      display: inline-flex;
      padding: 0 10px;
      margin-top: 40px;

      .owl-dot {
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background: $charcol;
        margin: 0 5px;
        position: relative;
        top: 10px;
        bottom: 10px;

        &.active {
          background: $pantone_cyan;
        }
      }
    }
  }

  &._with-icon {
    .owl-stage {
      display: flex;
      align-items: center;
    }

    .owl-item {
      display: inline-block;
      height: 100%;
    }

    .highlights_carousel {
      .owl-nav {
        position: relative;
        top: 44px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .highlights_carousel__container {
      padding: 64px 25px;

      .highlights_carousel {
        .owl-dots {
          height: 20px;
          padding: 0 5px;
          max-width: 66%;

          .owl-dot {
            width: 7px;
            height: 7px;
            top: 6px;
            bottom: 6px;
          }
        }
      }
    }
  }
}

.dynamic_block.features {
  .highlights_carousel__title {
    color: $white;
  }

  .highlights_carousel {
    .owl-nav {
      .owl-prev,
      .owl-next {
        &::before {
          background-color: $white;
        }
      }
    }

    .owl-dots {
      .owl-dot {
        background: $white;

        &.active {
          background: $pantone_cyan;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .highlights_carousel {
    .highlight {
      .highlight__icon {
        min-height: 120px;
      }
      .highlight__title {
        padding-bottom: 0;
        min-height: 80px;
      }
      .highlight__description {
        min-height: 125px;
      }
    }
  }
}
