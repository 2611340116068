// Banner
//
// Components Banner
//
// Markup: banner.twig
//
// Style guide: components.banner
$container_xs: if($cl_mobile, $cl_mobile, 479px);
$container_sm: if($cl_sm, $cl_sm, 768px);
$container_md: if($cl_md, $cl_md, 992px);
$container_lg: if($cl_lg, $cl_lg, 1025px);
$container_xl: if($cl_xl, $cl_xl, 1200px);

.banner {
  padding: 40px 15px 40px 15px;
  background-size: cover;
  background-position: center center;
  font-family: $cl_intel_font-family-light;
  position: relative;
  height: auto;
  box-sizing: border-box;
  font-size: $fsz-main-feature-body;

  &.software,
  &.blog,
  &.documentation,
  &.bundle {
    background-image: url(../sass/components/components/banner/assets/bg_software.png);

    .banner__gradient {
      opacity: 0;
    }
  }

  &.blog {
    background-image: url(../sass/components/components/banner/assets/bg_about.png);
  }

  &.documentation {
    background-image: url(../sass/components/components/banner/assets/bg_developer.png);
  }

  .banner__subheader,
  .banner__title {
    color: $white;
    font-family: inherit;
    letter-spacing: normal;
    font-weight: normal;
  }

  .banner__title {
    font-size: $fsz-main-feature-heading;
    font-family: $cl_intel_font-family-light;
    margin-bottom: 0;
  }

  .banner__subheader {
    font-size: $fsz-main-feature-body;
    font-family: $cl_intel_font-family;
    line-height: $lh-main-feature-body;
    margin-top: 35px;
    margin-bottom: 0;
  }

  .banner__actions__wrapper {
    margin-top: 35px;
    padding-top: 2rem;
  }

  .banner__gradient {
    position: absolute;
    height: 100%;
    width:100%;
    top: 0;
    left: 0;
    background: linear-gradient(-45deg, #064f8b 0%, #006ec8 50%, #3cc6e5 100%);

    &.banner__gradient_1 {
      background: linear-gradient(90deg, #064f8b 0%, #006ec8 50%, #3cc6e5 100%);
    }

    &.banner__gradient_2 {
      background: linear-gradient(-225deg, #10537c 0%, #072946 100%);
    }

    &.banner__gradient_3 {
      background: linear-gradient(-42deg, #064f8b 0%, #006ec8 47%, #27bef3 86%, #85e2b7 100%);
    }

    &.banner__gradient_4 {
      background: linear-gradient(90deg, #00aeff 3%, #35bff1 46%, #86e0b9 88%);
    }

    &.banner__opacity {
      opacity: 0;
    }
  }

  .banner__container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;

    .banner__support_img {
      position: relative;
      left: initial;
      bottom: initial;
      padding: 0;
      width: 100%;
      text-align: center;
      margin-top: 25px;

      img {
        max-height: 305px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: auto;
        align-self: center;
      }
    }

    .banner__content {
      text-align: left;
      width: 100%;

      h1 {
        font-family: $cl_intel_font-family-bold;
      }
    }
  }

  @media only screen and (max-width: $cl_mobile) {
    .banner__container {
      padding: 0 30px;
    }

    .banner__title {
      font-size: 32px;
      text-align: left;
    }

    .banner__support_img {
      padding: 0 0 30px 0;
      text-align: left;

      .banner__svg {
        width: 67px;
      }
    }
  }

  @media (min-width: 480px) and (max-width: 991px) {
    .banner__support_img {
      padding: 0 0 35px 0;

      .banner__svg {
        width: 101px;
      }
    }
  }

  @media only screen and (min-width: $cl_md) {
    .banner__container {
      position: relative;

      .banner__support_img {
        margin-top: 0;
      }

      .banner__support_img, 
      .banner__content {
        width: calc(100% * 1/2 - 20px);
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media only screen and (min-width: $cl_sm) {
    .banner__container {

      .banner__support_img, 
      .banner__content {
        width: calc(100% * 1/2 - 20px);
        display: flex;
        flex-direction: column;
      }
    }

    .flex__grid {
      display: flex;

      > div {
        flex: 50%;
      }
    }
  }

  .banner__svg .banner_opacity {
    opacity: 0.45;
  }
}
