/* TODO: It should be commit for correct works of tooltip */
/* It will be refactor in the future */
#software,
.card__footer,
.card_stack {

  .tool {
    position: absolute;
    top: 48px;
    left: 85%;
    width: 325px;
    height: auto;
    background-color: #296d88;
    cursor: auto;
    background: -moz-linear-gradient(-45deg, #296d88 0%, #296d88 50%, #28667e 51%, #28667e 100%);
    background: -webkit-linear-gradient(-45deg, #296d88 0%, #296d88 50%, #28667e 51%, #28667e 100%);
    background: linear-gradient(140deg, #296d88 0%, #296d88 65%, #28667e 51%, #28667e 100%);
    padding: 20px;
    z-index: 2;

    button {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 0 20px;
      border-color: transparent transparent transparent #296d88;
    }

    h3 {
      color: $white;
      font-family: $cl_intel_font-family;
      font-weight: 100;
      font-size: 20px;
      margin-top: 0;
    }

    span {
      display: block;
      color: $gray;
      font-size: 13px;
    }

    span:nth-child(2) {
      color: $white;
      font-weight: bold;
    }

    code {
      display: inline-block;
      background-color: #4ac29e;
      color: $white;
      padding: 5px 10px;
      margin-top: 10px;
      position: relative;
      word-break: break-all;

      &:before {
        content: '\f0c5';
        font: normal normal normal 16px/1 FontAwesome;
        font-weight: bold;
        margin-right: 15px;
        color: $gray_alt;
      }

      &:hover {
        cursor: pointer;
      }

      span {
        background-color: #296d88;
        color: $white;
        font-size: 13px;
        font-weight: 100;
        position: absolute;
        padding: 5px;
        bottom: -35px;
        left: 30px;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 0 10px;
          border-color: transparent transparent transparent #296d88;
        }
      }
    }

    p {
      color: $grayish;
      font-size: 13px;
      font-weight: bold;

      &:first-line {
        color: $white;
      }
    }
  }

  .tool.tool__viewport {
    top: auto;
    bottom: 48px;
  }

  .tool.tool__viewport:before {
    top: auto;
    bottom: -20px;
    border-width: 0 20px 20px 0 !important;
    border-color: transparent #28667e transparent #28667e !important;
  }
}

.card__footer .tool {
  top: 60px;

  &.tool__viewport {
    bottom: 70px
  }
}

.card_stack .tool {
  top: 45px;

  &.tool__viewport {
    bottom: 45px
  }
}

@media only screen and (min-width: $cl_md) {
  .column__second {

    .card__footer .tool,
    .card_stack .tool {

      &:before {
        left: auto;
        right: 0;
        border-width: 20px 20px 0 20px !important;
        border-color: transparent #296d88 transparent transparent !important;
        top: -20px;
      }

      &.tool__viewport {

        &:before {
          bottom: -20px;
          top: initial;
          border-width: 0 20px 20px 0 !important;
        }
      }
    }
  }
}

.d-none {
  display: none;
}

@media only screen and (min-width: $cl_lg) {
  #software {
    .view-bundles-search-view {
      .view-content {
        .views-row {
          .tool.tool__viewport:before {
            border-width: 0 0 20px 20px !important;
            border-color: transparent #296d88 transparent #296d88 !important;
          }
        }

        .views-row:nth-child(3n) {
          .tool {
            left: auto;
            right: 15px;
          }

          .tool:before {
            left: auto;
            right: 0;
            border-width: 20px 20px 0 20px !important;
            border-color: transparent #296d88 transparent transparent !important;
          }

          .tool.tool__viewport:before {
            border-width: 0 20px 20px 20px !important;
            border-color: transparent #28667e transparent transparent !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $cl_lg) {
  #software {
    .view-bundles-search-view {
      .view-content {
        .views-row {

          .tool {
            width: auto;
            left: auto;
            right: 20px;
          }

          .tool:before {
            left: auto;
            right: 0;
            border-width: 20px 20px 0 20px;
            border-color: transparent #296d88 transparent transparent;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $cl_sm) {
  #software {
    .view-bundles-search-view {
      .view-content {
        .views-row {

          .tool {
            width: auto;
            left: auto;
            right: 20px;

            h3 {
              font-size: 14px;
              line-height: 18px;
            }

            span {
              font-size: 12px;
            }

            code {
              display: flex;
              align-items: center;
              font-size: 0.85em;
            }
          }

          .tool:before {
            left: auto;
            right: 0;
            border-width: 20px 20px 0 20px;
            border-color: transparent #296d88 transparent transparent;
          }
        }
      }
    }
  }
}
