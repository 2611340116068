article {
  &.profile {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 25px 0;
    @media only screen and (min-width: $cl_md) {
        @include container();
    }
  }
}
