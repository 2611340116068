// Card icon
//
// Components Card icon
//
// Markup: card_icon.twig
//
// Style guide: components.card_icon

.views-row {
  width: 320px;
  display: flex;
  margin-top: $padding_small;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  &:first-child {
    margin-top: 0!important;
  }

  @media only screen and (min-width:$cl_sm) {
    width: calc(100% * 1/2 - 20px);
    margin-left: !important;
    margin-right: !important;
  }

  @media only screen and (min-width: $cl_lg) {
    width: calc(100% * 1/3 - 20px);
  }

  .card_icon {
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    padding: 30px;
    background-color: $white;
    width: 100%;

    .call_to_action {
      border-radius: 0;
      font-family: $cl_intel_font-family;
      border: 0;
      padding: 0;
      margin-top: 5px;

      i.fa {
        font-size: 12px;
        margin-right: 0;
        margin-left: 15px;
        float: right;
      }

      &:hover {
        background: none;
        color: $intel;
        text-decoration: underline;
      }
    }

    &__header {
      font-size: 2rem;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      font-family: $cl_intel_font-family-bold;

      i.fa {
        color: $intel;
        float: left;
        padding: 5px;
        font-size: 1.25rem;
        margin-right: 5px;
      }
    }

    &__content {

      h3 {
        font-family: $cl_intel_font-family-bold;
        margin-bottom: 10px;
      }

      ul {
        padding: 0;
        list-style: none;

        li {
          border-top: solid 1px $gray_light_4;
          border-right: solid 1px $gray_light_4;
          border-left: solid 1px $gray_light_4;
          position: relative;
          margin-bottom: 0;
          padding: 3px 5px;

          &:last-child {
            border-bottom: solid 1px $gray_light_4;
          }

          a {
            width: 100%;
            color: $intel;
            display: inline;

            i.fa {
              font-size: 11px;
            }
          }
        }

        &.documentation {
          list-style: none;
          padding-left: 15px;

          li {
            border: none;
            padding: 0;

            a {
              padding: 0;
              color: $charcol;
              display: inline;
            }

            i.fa {
              font-size: 11px;
            }

            &::before {
              content: "\25A0";
              color: $intel;
              display: inline-block;
              width: 1em;
              height: 1em;
            }

            &::after {
              content: '';
            }
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding: 20px;

      &__icon {
        padding: 10px;
      }

      &__content {
        text-align: left;
        padding: 10px;
      }
    }
  }

  // Modified Class
  &.large {
    @media only screen and (min-width:$cl_sm) {
      width: 100%;
    }

    .card_icon {

      &__header {
        display: flex;
      }
    }
  }

  .alias--developer & {
    margin: 10px 0;

    @media only screen and (min-width:$cl_sm) {
      width: 100%;
      display: inline-block;

      .card_icon {
        width: initial;

        &__content {
          padding-left: 0;
        }
      }
    }

    .card_icon {

      &__header {
        display: flex;
      }
    }
  }

  &.list {

    @media only screen and (min-width:$cl_sm) {
      width: calc(100% * 1/2 - 20px);
    }

    .card_icon {

      &__header {
        display: flex;
      }
    }
  }
}