// Layout
//
// Sidebars Region Styling
//
// Markup: sidebars.twig
//
// Style guide: layout.sidebars

//////////////////////////////////
// Sidebar First /////////////////
//////////////////////////////////
.body-sidebars-first {
  @include breakpoint($cl_lg) {
    aside.sidebar-first {
      @include span(3 of 12);
    }
    section#content {
      @include span(9 of 12 last);
    }
  }
}
//////////////////////////////////
// Sidebar Second ////////////////
//////////////////////////////////
.body-sidebars-second {
  @include breakpoint($cl_sm) {
    aside.sidebar-second {
      @include span(3 of 12 last);
    }
    section#content {
      @include span(9 of 12);
    }
  }
}
//////////////////////////////////
// Sidebar Both //////////////////
//////////////////////////////////
.body-sidebars-both {
  @include breakpoint($cl_sm) {
    aside.sidebar-first {
      @include span(2 of 12);
      > * {
        padding-left: 15px;
      }
    }
    section#content {
      @include span(8 of 12);
      > * {
        padding-right: 25px;
      }
    }
    aside.sidebar-second {
      @include span(2 of 12 last);
      > * {
        padding-right: 15px;
      }
    }
  }
}
//////////////////////////////////
// Sidebar None //////////////////
//////////////////////////////////
.body-sidebars-none {
  section#content {
    @include span(12 of 12);

  }
}
//////////////////////////////////
// General Sidebar Rules /////////
//////////////////////////////////
aside.sidebar {
  overflow: hidden;
}
