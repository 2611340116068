// Text block
//
// Components: Text block
//
// Markup: text_block.twig
//
// Style guide: components.text_block
.Text {
  display: block;
  width: 100%;
  margin-bottom: $space_md;

  &__title {
    font-family: $cl_intel_font-family;
    font-weight: bold;
    font-size: $fsz-tile-heading;
    line-height: 1.23em;
    margin-bottom: 16px;
    color: $button;
  }

  &__subtitle {
    font-family: $cl_intel_font-family;
    font-weight: bold;
    font-size: 1em;
    line-height: 1em;
    margin-bottom: 16px;
    color: #055495;
    display: block;
  }

  &__description {
    p {
      font-size: $fsz-tile-body;
      line-height: 29px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        color: $intel;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    article {

      img {
        display: block;
        margin: 20px auto;
      }
    }
  }

  .call_to_action__secondary  {
    margin: 10px 0 16px 0;
  }
}

.block--even {
  .Text {
    &__title {
      color: $white;
    }

    &__description {
      p {
        color: $white;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .Text {
    &__description {
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
