@import "../../../../bower_components/susy/sass/susy";

#software {
  aside {
    @include span(25%);
  }

  .search__results {
    @include span(70%);

    header, footer {
      width: 100%;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .view-bundles {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .view-bundles-search {
    .view-content {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;

      .views-row {
        padding: 15px 7px;
      }
    }
  }

  .pagination__block {
    margin-left: auto;
    margin-right: 10px;
    display: inline-block;

    .pager {
      padding: 0;

      .pager__items {
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0;
        }
      }
    }
  }

  .container__search__results {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}

// Background-color for software and bundle page.
.nodetype--bundle,
.alias--software {
  background-color: $gray_light_2;
}

@media only screen and (max-width: $cl_sm) {
  #software {
    .search__results {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    aside {
      width: auto;
      float: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0 10px;
    }
  }

  .view-bundles-search-view {
    .view-content {
      .views-row {
        @include span(50%);
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: $cl_mobile) {
  #software {
    .container {
      padding: 0;
    }

    .view-bundles-search-view {
      .view-content {
        .views-row {
          @include span(100%);
        }
      }
    }
  }
}
