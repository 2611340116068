// Homepage
//
// Layout Homepage
//
// Markup: homepage.twig
//
// Style guide: layout.homepage


.highlights_carousel {
	@extend %border-light--bottom;
}

.text_block {
	.container {
		@extend %container-md;
	}
}

.dynamic_block.features {
	@extend %bg-gradient--blue;
}

.blogs {
	.container {
		@extend %container-md;
	}
}

.community_counter {
	@extend %bg-gradient--light-blue;
}

.nodetype--homepage {

	#header {
		position: absolute;
    z-index: 100;
    background: transparent;
		border: 0;
		
		.header__site_name {
			color: $white;

			a {
				color: $white!important;
			}
		}
		
		.header__search {
			border: 0;

			.header__search_icon {

				&.visible {
					color: $white;
				}
			}
		}

		.header__menu_list {
			
			&_item {

				a {
					color: $dark_gray!important;
	
					@media only screen and (min-width: $cl_md) {
						color: $white!important;
					}
				}

				&:hover,
				&:focus {
					
					a {
						color: $white!important;
					}
				}
			}
		}
	}

	.banner {
		padding-top: 80px;

		&__content {
			width: 100%!important;
			text-align: center!important;
		}

		&__subheader {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		&__actions {

			&-item {
				display: inline-block;
    		margin: 10px;
			}
		}
	}

  .banner__container {
    &.flex__grid {
      .banner {
        &__subheader {
          margin-left: 0;
        }

        &__content {
          text-align: left !important;
        }
      }
    }
  }
}
