$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.messages,
.admonition {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  border-color: $wipinbell;
  display: flex;
  color: $wipinbell;
  border-left: solid 5px $wipinbell;
  max-width: 90%;
  margin: 25px auto;

  @media only screen and (min-width: $cl_xl) {
    @include container();
  }

  &--error {
    color: $form_error;
    border-color: $form_error;
    border-left: solid 5px $form_error;
  }

  &--status {
    color: $accent_color;
    border-color: $accent_color;
    border-left: solid 5px $accent_color;
  }

  &--warning {
    color: $pantone_cyan;
    border-color: $pantone_cyan;
    border-left: solid 5px $pantone_cyan;
  }

  &-ico {
    padding: 0 15px 0 25px;
    font-size: 1.25em;
  }

  &-body {
    display: flex;
    align-items: center;
    &.column {
      flex-direction: column;
      h2 {
        margin: 0 10px 5px 0;
      }
    }
    h2, p {
      font-size: 0.875em;
      line-height: 1.125em;
    }
    h2 {
      font-family: $cl_intel_font-family-bold;
      color: $alert_title;
      margin: 0 10px 0 0;
    }
    p {
      color: $alert_description;
      a {
        font-family: $cl_intel_font-family-bold;
        color: $alert_description;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin: 5px 0 0 0;
        }
      }
    }
  }
}

.admonition {
  color: $alert_description;
  display: block;
  padding: 10px 20px;

  p {
    color: $alert_description;
    font-size: 0.875em;
    line-height: 2.125em;
  }

  .admonition-title {
    font-family: $cl_intel_font-family-bold;
    color: $alert_title;
    line-height: 1.125em;
    position: relative;
    top: 2px;
    margin-bottom: 10px;

    &::before {
      color: $pantone_cyan;
      content: '\f05a';
      display: inline-block;
      padding: 0 15px 0 0;
      font: normal normal normal 20px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      top: 3px;
    }
  }

  .last {
    display: block;
    margin-left: 32px;

    &.highlight-console {
      margin-left: 0;
    }
  }

  &.caution {
    .admonition-title {
      &::before {
        color: $wipinbell;
        content: '\f05a';
      }
    }
  }

  &.note {
    border-color: $pantone_cyan;
    border-left: solid 5px $pantone_cyan;

    .admonition-title {
      &::before {
        color: $pantone_cyan;
        content: '\f05a';
      }
    }
  }
}
