// Card_menu
//
// Components Card_menu
//
// Markup: card_menu.twig
//
// Style guide: components.card_menu

.card__menu-section {
  border-bottom: solid 1px $dividers;
  color: $blue;

  &:last-child {
    border-bottom: none;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 10px;

      a {
        font-family: $cl_intel_font-family;
        font-size: $fsz-category-menu;
        margin-left: 25px;
        position: relative;
  
        &.fa {
          font-size: $fsz-category-menu;
          font-family: $cl_intel_font-family;
        }
  
        &:before {
          margin-right: 12px;
          font: normal normal normal 12px/1 FontAwesome;
          position: absolute;
          left: -23px;
          top: calc(50% - 6px);
          
          &:hover {
            text-decoration: none;
            font-family: $cl_poppins_font-family-Bold;
          }
        }
      }
  
      i {
        margin-right: 12px;
  
        &.icon-transparent {
          opacity: 0;
        }
      }
    }
  }
}