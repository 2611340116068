.Main {
  width: 100%;
  min-height: 100vh;
  display: block;
  margin: 0;
  padding: 0;
  &__page_standar {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &__inner_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }
  &__main {
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    &-content {
      width: 100%;
      margin: 0;
      &_sidebar {
        @include span(24);
        @media only screen and (min-width: $cl_sm) {
          @include span(16);
        }
      }
    }
    &-container {
      max-width: 1200px;
      margin: 70px auto 0 auto;
    }
    &-sidebar {
      @include span(24);
      @media only screen and (min-width: $cl_sm) {
        @include span(8);
      }
    }
  }
}

.view-footer {
  .article_list__rss {
    float: right;
  }
}
