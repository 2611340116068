// Community list
//
// Components Community list
//
// Markup: community_list.twig
//
// Style guide: components.community_list
.Clist {
  width: 100%;
  font-size: 16px;
  &__item {
    @include span(24 of 24);
    padding: 17px 10px;
    border-bottom: solid 1px $grayish;
    margin-bottom: 15px;
    box-sizing: border-box;
    @media only screen and (min-width: $cl_sm) {
      @include span(12 of 24);
    }
  }
  &__ico {
    float: left;
    width: 50px;
    height: 35px;
    i {
      font-size: 2em;
      color: $dark;
    }
  }
  &__body {
    float: right;
    width: calc(100% - 50px);
  }
  &__title {
    font-family: $cl_intel_font-family;
    font-size: 1.125em;
    line-height: 1.66em;
    padding: 0;
    margin: 0 0 20px 0;
  }
  &__description {
    font-family: $cl_intel_font-family-light;
    font-size: 0.9375em;
    line-height: 1.466em;
    color: $charcol;
  }
}
