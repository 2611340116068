#block-sharethis {
  position: relative;
  display: block;
  margin-top: $padding_small;
  margin-bottom: $padding_small;

  @media only screen and (min-width: 1310px) {
    position: absolute;
    top: 20px;
    left: -70px;
  }
}

.sharethis-wrapper {
  width: auto;
  height: 20px;
  margin: 0 auto;
  font-family: $cl_poppins_font-family-medium;
  font-size: $fsz-tile-body;
  color: $button;
  letter-spacing: 0.19px;
  align-items: center;
  
  &::before {
    content: "Share";
    color: $text-light;
    margin: 0;
    display: block;
    float: left;
    margin-right: $padding_small;
    font-size: $fsz-tile-aditional;
  }

  @media only screen and (min-width: 1310px) {
    text-align: center;

    &::before {
      content: "Share";
      color: $text;
      margin: 0;
      display: block;
      font-size: initial;
    }
  }

  a{
    width: 100%;
    height: auto;
    margin: 0;
    display: inline;
    padding: 5px;

    &:hover {
      text-decoration: none;
    }

    @media only screen and (min-width: 1310px) {
      display: block;
      padding-bottom: $padding_small;
    }

    &::before {
      font: normal normal normal 1.5rem FontAwesome;
      color: $intel;
      cursor: pointer;
      &:hover {
        color: $charcol;
      }
    }
    &.st_facebook_custom {
      &::before {
        content: "\f230";
      }
      &:hover {
        &::before {
          color: $facebook-color;
        }
      }
    }
    &.st_twitter_custom {
      &::before {
        content: "\f099";
      }

      &:hover {
        &::before {
          color: $twitter-color;
        }
      }
    }
    &.st_linkedin_custom {
      &::before {
        content: "\f08c";
      }

      &:hover {
        &::before {
          color: $linkedin-color;
        }
      }
    }
  }
}