// Breadcrumb
//
// Layout Breadcrumb
//
// Markup: breadcrumb.twig
//
// Style guide: layout.breadcrumb
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.breadcrumb, .breadcrumb.primary {
  background: transparent;
  display: none;

  .breadcrumb__list {
    margin: 0;
    padding: 0;

    .breadcrumb__list__item {
      display: inline-flex;
      list-style: none;
      font-family: $cl_intel_font-family;
      font-weight: bold;
      font-size: 12px;
      color: $white;
      letter-spacing: 0;
      line-height: 30px;
      text-align: left;

      a {
        color: $white;
        text-decoration: underline;
      }
    }

    .breadcrumb__list__item:not(.current) {
       &:after {
         padding-left: 10px;
         padding-right: 10px;
         content: '/';
       }
    }

    .breadcrumb__list__item.current {
      font-weight: normal;
    }
  }

}

.breadcrumb.secondary {
  .breadcrumb__list__item {
    color: $intel;
     a {
        color: $intel;
    }
  }
}

@media only screen and (min-width: $cl_lg) {
  .breadcrumb {
    display: inline-flex;
  }
}
