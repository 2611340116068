// Header
//
// Components Header
//
// Markup: header.twig
//
// Style guide: layout.header
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

#header {
  width: 100%;
  display: block;
  font-family: $cl_intel_font-family;
  font-size: $fsz-category-menu;
  background: $white;

  > .container {
    display: flex;
  }

  #header__site_info {
    position: relative;
    display: inline-flex;
    margin: 15px 5px 0 15px;

    .header__site_name {
      margin: 15px 5px;
      font-family: $cl_intel_font-family;
      color: $black_light;
      letter-spacing: -0.25px;
      line-height: 21px;
      text-align: left;
      min-width: 160px;

      a,
      a:visited,
      a:active,
      a:focus {
        color: $black_light;
      }
    }
  }

  .header__menu {
    .header__menu_list {
      list-style-type: none;
      
      .header__menu_list_item {
        display: inline-block;
        position: relative;
        margin-bottom: 0;
        margin-left: 0;
        min-width: 1px;
        max-width: 100%;
        padding: 0;
        text-align: left;
        top: 22px;

        &.active {
          > a {
            font-family: $cl_poppins_font-family-Bold;

            &::before {
              content: '•';
              font-family: $cl_intel_font-family;
              font-size: 35px;
              margin-right: 10px;
              position: absolute;
              top: -3px;
              left: 12px;
            }
          }
        }

        > a,
        i {
          position: static;
          line-height: 35px;
        }

        > a {
          color: $dark_gray;
          display: inline-block;
          border-radius: 48px;
          font-family: $cl_intel_font-family;
          height: 35px;
          margin: 0 2px;
          padding: 0 20px 0 30px;
          position: relative;
          text-align: center;

          &::before {
            content: '•';
            font-size: 35px;
            margin-right: 10px;
            position: absolute;
            top: -3px;
            left: 12px;
          }

          &:focus, 
          &:hover,
          &:active {
            outline: none;
          }

        }

        > i {
          position: absolute;
          right: 20px;
          top: 0;
        }

        &.green {
          > a {
            &::before {
              color: $teal-dark;
            }
          }
        }

        &.purple {
          > a {
            &::before {
              color: $purple;
            }
          }
        }

        &.blue {
          > a {
            &::before {
              color: $blue-dark;
            }
          }
        }

        .header__menu_list__child_item {
          margin-left: 0;
        }

        .header__menu_list__child {
          margin-top: 0;
          margin-left: 0;
          padding: 0 15px;
        }

        &:focus, 
        &:hover,
        &:active, 
        &.active, 
        &.active-trial, 
        &.collapsed {
          outline: none;

          > a {
            color: #fff;
            text-decoration: none;
          }
          
          &.green {
            > a {
              background: $teal-dark;

              &::before {
                color: #fff;
              }
            }
          }

          &.purple {
            > a {
              background: $purple;

              &::before {
                color: #fff;
              }
            }
          }

          &.blue {
            > a {
              background: $blue-dark;

              &::before {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .header__menu {
    width: 75%;
    max-width: 75%;
    text-align: right;

    &.collapsed {
      z-index: 2;
    }

    &.search {
      display: none;
    }

    .header__menu_list {
      .header__menu_list__child {
        display: none;
        list-style: none;

        &.collapsed {
          display: block;
          position: absolute;
          margin-top: 58px;
          box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.50);
          margin-left: -16px;
          padding: 0 15px 20px 15px;
          background: $white;
          z-index: 2;

          .header__menu_list__child_item {
            height: 50px;
            border-bottom: 1px solid $gray;
            min-width: 200px;
            text-align: left;
            width: 200px;

            &:last-child {
              border-bottom: none;
            }

            a {
              font-family: $cl_intel_font-family;
              color: $dark_gray;
              display: block;
              font-weight: normal;
              padding-bottom: 15px;
              margin-left: 5px;
              top: 20px;
              height: 80px;
              padding: 0;
            }

            &:before {
              display: none;
            }

            &:hover, &.active, &.active-trail, &:active, &:focus {
              a {
                color: $intel;
              }
            }
          }
        }
      }
    }
  }

  .header__menu_mobile {
    text-align: center;
    cursor: pointer;
    background: $white;
    display: block;
    float: left;
    margin: 5px 0 0 -10px;
    width: 45px;

    &:hover, &.collapsed {
      background: $gray;
    }

    .header__menu_mobile__control {
      font-family: FontAwesome;
      font-size: 24px;
      color: $intel;
      letter-spacing: 1.84px;
      position: relative;
      padding: 10px;
    }
  }

  .header__search {
    text-align: left;
    position: relative;
    float: left;

    &.active {
      float: right;
      width: 100%;
    }

    .header__search_form__input,
    .header__search_placeholder_icon{
      margin-top: 30px;
    }

    .header__search_icon {
      &.visible {
        display: block;
        font-family: FontAwesome;
        font-size: $fsz-app-tile-heading;
        color: $black_light;
        letter-spacing: 1.84px;
        cursor: pointer;
        margin: 30px 0 0 20px;

        &:hover {
          color: $intel;
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    #header__site_info {
      position: relative;
      margin-top: 5px;

      .header__site_img_object {
        height: 40px;
      }

      .header__site_name {
        font-size: 18px;
        text-align: center;
        margin: 10px 5px;
      }
    }

    .header__menu {
      display: none;

      .header__menu_list {
        display: block;
        position: absolute;
        background: $gray_light;
        padding: 15px;
        width: 150%;
        margin-left: -32px;
        top: -1px;

        .header__menu_list_item {
          display: block;
          margin-bottom: 15px;
          margin-left: 15px;
          top: 0;

          &.collapsed {
            background-color: $white;
            height: 100%;
            box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);

            .header__menu_list__child {
              position: relative;
              margin-top: 10px;
              margin-bottom: 10px;
              box-shadow: none;
              margin-left: -16px;
              padding: 0;
              background: transparent;

              .header__menu_list__child_item {
                border: none;
                height: 32px;
                margin-bottom: 0;
                display: block;
                width: 100%;

                &:last-child {
                  padding-bottom: 10px;
                }

                 a {
                  padding-left: 40px;
                  padding-bottom: 0;
                  top: 0;
                }
              }
            }
          }
        }
      }

      &.collapsed {
        position: absolute;
        margin: 50px 0;
        width: 100%;
        display: block;
      }
    }

    .header__search {
      border: none;
      text-align: right;
      float: right;
      height: 49px;
      width: 100%;

      .header__search_form__wrapper {
        margin: 15px 0;
      }

      .header__search_form__input,
      .header__search_placeholder_icon{
          margin-top: 0px;
      }

      .header__search_icon {
        &.visible {
          margin: 15px 0 0 0;
        }
      }

      &.active {
        position: absolute;
        width: 100%;
        left: 0;
        background: $white;

        .header__search_cancel {
          display: inline;
          margin-top: 15px;
        }
      }
    }
  }

  @media only screen and (min-width: $cl_md) {
    .header__menu_mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 530px) {
    .header__search {
      .header__search_form__input {
        max-width: 150px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #header {
    border: none;
    height: auto;
  }
}

.header {
  &__site {
    &_txt_object {
      color: #000;
      height: 27px;

      svg {
        fill: #000;
      }
    }

    &_img_object {
      height: 48px;
      margin-right: 5px;
    }

    &_img_wrapper {
      position: relative;
      top: -8px;
      
      a {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

.frontpage {
  .header__site_txt_object {
    color: #fff;
  }
}