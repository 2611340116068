// Sidebar Nav
//
// Layout Sidebar Nav
//
// Markup: sidebar_nav.twig
//
// Style guide: layout.sidebar_nav
.sidebar_nav {
  max-width: 288px;
  font-family: $cl_intel_font-family;
  display: none;

  .sidebar_nav__title {
    font-size: bold;
    font-size: 20px;
    color: $black_light;
    letter-spacing: 0;
    text-align: left;
  }

  .sidebar_nav__menu {
    .sidebar_nav__menu__list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        a {
          font-family: $cl_intel_font-family;
          color: $dark_gray;
          text-decoration: none;

          &:hover,
          &:active,
          &:focus,
          &.active,
          &.active-trail {
            color: $intel;
          }
        }
      }

      &__parent {
        height: 100%;
        text-align: left;
        display: block;
        font-family: $cl_intel_font-family;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0;
        text-align: left;
        padding: 0;
        margin: 0 !important;

        &__wrapper {
          display: flex;

          &__link {
            padding: 8px 10px 10px 0;
          }

          &__icon {
            i {
              color: $intel;
              padding: 10px;
              cursor: pointer;
              width: 10px;
              font-family: FontAwesome;
            }
          }
        }

        &:hover,
        &:active,
        &:focus,
        &.active,
        &.active-trail {

          > .sidebar_nav__menu__list__parent__wrapper {
            
            .sidebar_nav__menu__list__parent__wrapper__link {
              a {
                color: $intel;
              }
            }
          }
        }

        ul {
          display: block;

          li {

            &:before {
              display: none;
            }

            a {
              font-family: $cl_intel_font-family;
              color: $dark_gray;
              text-decoration: none;

              &:hover,
              &:active,
              &:focus,
              &.active,
              &.active-trail {
                a {
                  color: $intel;
                }
              }
            }
          }
        }

        &.active {
          > ul {
            display: block;
          }
        }

        &.collapsed {
          > ul {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $cl_lg) {
  .sidebar_nav {
     display: block;
  }
}
