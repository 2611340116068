// Card dynamic item
//
// Components Card_dynamic_item
//
// Markup: card_dynamic_item.twig
//
// Style guide: components.card_dynamic_item

.card__dynamic_item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $padding_big;
  font-size: $fsz-app-tile-body;

  .text-aditional {
    color: $text-light;
    font-size: $fsz-app-tile-aditional;
  }

  h3 {
    margin-bottom: $padding_small;
    font-family: $cl_intel_font-family-bold;
    font-size: $fsz-app-tile-heading;
    line-height: $fsz-app-tile-heading;
  }

  p {
    margin-top: $padding_small;

    &:last-child {
      margin-bottom: initial;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.content_divided {
      width: calc(100% - 42px - 20px);
    }

    &.card__dynamic_item--image {
      width: 42px;
      padding: 0!important;
    }
    
    a {
      color: $charcol;
    }
  }

  i {
    margin: 0 auto;
    display: block;
    width: 42px;

    &.fa {
      font-size: 42px;
    }
  }

  img {
    padding: 0 0 $padding_small 0!important;
    width: auto!important;
    margin: 0 auto;
    max-width: 100%;
    display: block;
    float: inherit!important;
  }

  &.search__item {
    .text-aditional {
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        color: $text-light;
        display: inline-block;
        font-size: $fsz-app-tile-aditional;
        margin: 0;
      }
    }

    h3 {
      margin-bottom: 0px;
    }

    &:hover {
      color: $text;
      text-decoration: none;
    }

    .section {

      p {
        margin-top: 0;
      }

      br {
        display: none;
      }

      a {
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
      }
    }

    br {
      display: none;
    }

    a {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.views-search-results {
  
  .view__results {
    position: relative;
  }

  .views-row {
    display: block;
    width: 100%;
  }

  header {
    font-family: 'IntelClear-Bold';
    font-size: $fsz-tile-heading;
    padding-right: 200px;
  }

  .Column {
    padding: $padding_big 0;
  }

  .Column__item {
    position: relative;
  }

  .pager__items {
    list-style-type: none;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 5px;
    }
  }

  .pager {
    position: absolute;
    right: 0;
    top: 0;

    &__item {
      &.is-active {
        font-family: 'IntelClear-Bold';
      }

      a {
        color: $text;

        &:visited {
          color: $text;
        }

        &:hover {
          color: $text;
        }
      }
    }
  }
}