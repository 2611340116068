// Page
//
// Layout Page
//
// Markup: page.twig
//
// Style guide: layout.page
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.downloads {
  &__all {
    padding: $comp_space_md 0;

    &__subtitle {
      color: $intel;
      font-family: $cl_intel_font-family-bold;
    }

    &__subdesc {
      margin-bottom: $comp_space_sm;
    }
  }

  &__pinned {
    border-bottom: 2px solid $submenu_border;
    padding: $comp_space_md 0;
  }
}

.nodetype--generic_page {
  .two_column {
    box-sizing: border-box;
    padding: 1rem;

    .Title {
      .container {
        padding: 16px;
      }
    }

    .call_to_action {
      margin-bottom: $comp_space_md/2;
    }
  }
}

.alias--stacks {

  .text_block {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.alias--developer {

  .content--wrapper {

    .padding--top-bottom {
      padding-top: 20px;
      padding-bottom: 0px;

      &.two_column {
        padding-top: 0;
      }

      .Title {
        margin-bottom: 25px;
      }
    }

    section {
      
      &.call-to-action {
        padding: 7px 20px!important;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

body[class*="alias--stacks"] {

  .Column {

    .call_to_action {
      padding: 12px 20px!important;
      display: block;
      width: 100%;
    }
  }

  .Image {

    &__description {
      display: none;
    }
  }

  .card_stack {
    padding: 30px 40px;
  
    .call_to_action {
      margin-bottom: 1rem;
      max-width: 100% !important;
    }

    &__title {
      h3 {
        font-size: $fsz-app-tile-heading;
      }

      .fa {
        font-size: $fsz-app-tile-heading;
        top: 5px;
      }
    }
  }
}

span {

  &.ext {
    display: inline-block!important;
  }
}

iframe {
  border: none;
}
