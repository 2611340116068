#software,
#bundle {
  .bundles__category__search {
    .views-exposed-form {
      position: relative;
      margin: 0;

      .form-item-search-api-fulltext {
        float: none;

        input[type="text"] {
          color: $text-light;
          background-color: transparent;
          border-bottom: 1px solid $gray;
          font-size: 12px;
          padding: 10px 40px 10px 16px;
          box-shadow: none;
          margin: 0;
          max-height: 40px;
          max-width: 100%;
          width: 100%;

          &::placeholder {
            color: $text-light;
          }
        }
      }

      .form-actions {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          &[type="submit"] {
            margin: 0;
            opacity: 0;
            padding: 0;
            min-height: 40px;
            min-width: 30px;
          }
        }

        &::before {
          content: '\f002';
          color: $gray_alt;
          position: absolute;
          font: normal normal normal 16px/1 FontAwesome;
          font-size: 20px;
          display: block;
          left: 50%;
          top: 50%;
          margin-left: -10px;
          margin-top: -10px;
        }
      }
    }
  }

  .categorie__headline {
    color: $black_light;
    font-size: 16px;
    font-family: $cl_intel_font-family;
    font-weight: bold;
  }

  .bundles__lts__list {

    &-select {
      display: flex;
      padding: 5px;
      border-bottom: 1px solid $gray;
      margin: 10px 0;
      cursor: pointer;

      #selected-bundles {
        flex: 2;
        text-transform: uppercase;
      }
    }

    &-items {
      background-color: white;
      padding: 20px 20px 10px 20px;
      display: none;

      &.active {
        display: block;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin-bottom: 10px;
        }

        label {
          font-weight: 100;
          color: $gray_alt;
          text-transform: uppercase;
          cursor: pointer;
          
          input {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

#bundle {
  .bundle__details__search {
    width: 30%;
    margin-bottom: 20px;
  }
}

.search__headline {
  color: $text;
  font-family: $cl_intel_font-family;
  font-size: 16px;
  line-height: 24px;

  span {
    font-weight: bold;
  }
}

@media only screen and (max-width: $cl_sm) {
  #software {
    .bundles__category__search {
      position: relative;

      .views-exposed-form {
        .form-actions {
          left: 0;
          right: auto;
        }

        .form-item {
          margin-left: 40px;
        }
      }
    }

    .container__search__results, .pagination__block {
      margin-bottom: 8px;
    }
  }

  #bundle {
    .bundle__details__search {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
