//colors
$black_light: #2d3237;
$dark: #0e1213;
$charcol: #3b404c;
$dark_charcol: #051b2c;
$gastly: #626178;
$pantone_541: #003C71;
$intel: #0070c5;
$pantone_cyan: #00aeff;
$blue_light: #7DD2F7;
$clear_blue: #338dd1;
$venonat: #7B16FF;
$nidoran: #9463ff;
$wipinbell: #F3D64E;
$accent_color: #03DCA7;
$accent_color_light: #52F4A5;
// $gray: #d1d3d5;
$gray_alt: #6c7073;
$gray_light: #f6f7f8;
$gray_light_2: #F0F2F4;
$gray_light_3: #F2F3F4;
$gray_light_4: #CCCCCC;
$dark_gray: #4c5155;
$grayish: #d8d8d8;
$light_blue_gray: #F1F3F8;
$white: #ffffff;
$controls: #d8d8d8;
$containers: #9e00d9;
$submenu_hover: #b1e4fa;
$submenu_border: #dfe6eb;
$submenu_background: #f9fbff;
$footer_light: #F4FBFE;
$footer_color: #58595B;
$footer_icons: #025595;
$footer_bottom: #424872;
$footer_bottom_color: #CDCDD2;
$border_footer: #979797;
$button: #22252d;
$button_disabled: #75758b;
$button_blue_disabled: #66a9dc;
$button_blue_hover: #338dd1;
$form_error: #e71d32;
$alert_title: #152934;
$alert_description: #5a6872;
$menu_border: #1faeeb;

// New colors
$text: #2D3237;
$text-light: #8C9196;
$code-text: #6D7073;
$code-background: #C7E6DA;
$dividers: #ECECED;
$gray-dark: #AFB2B6;
$gray: #D2D2D4;
$teal-dark: #009B93;
$teal: #00B4B1;
$teal-light: #CCF0EF;
$purple-dark: #6E517F;
$purple: #6E4C9F;
$purple-light: #E3D3E8;
$blue-dark: #358CCB;
$blue: #3BBDEE;
$blue-light: #CCE9F5;
$white: #ffffff;
$black: #000000;

$tab-green-active: #02C8C0;
$tab-purple-active: #926BB8;

$background: linear-gradient(90deg, #F0F2F4, #FAFAFB, #F0F2F4);

// Facebook
$facebook-color: #3b5998;
// Twitter
$twitter-color: #55acee;
// GooglePlus
$google-plus-color: #dd4b39;
// LinkedIn
$linkedin-color: #0077b5;
// YouTube
$youtube-color: #cd201f;
// GitHub
$github-color: #6e5494;

$pinterest-color: #bd081c;
$tumblr-color: ligthen(#35465c, 20%);
$reddit-color: #ff4500;
$snapchat-color: #fffc00;
$instagram-color: #f77737;
$code-keywords: #F92672;

// spacing
$space_sm: 16px;
$space_md: 32px;
$space_lg: 64px;
$space_xl: 96px;
$line: $gray;

// Paddings
$padding_small: 20px;
$padding_big: 35px;
$padding_medium: 30px;
$padding_card: 30px 40px 55px;
$padding_horizontal: 40px;

//Font awesome
$fa-font-path: '../fonts/fontAwesome/';

//Intel font
$cl_intel_font-path: '../fonts/IntelFonts/fonts/';
$cl_intel_font-family: 'IntelClear-Regular', Helvetica, Arial, sans-serif;
$cl_intel_font-family-light: 'IntelClear-Light', Helvetica, Arial, sans-serif;
$cl_intel_font-family-bold: 'IntelClear-Bold', Helvetica, Arial, sans-serif;
$cl_intel_font-family-probold: 'IntelClearPro-Bold', Helvetica, Arial, sans-serif;

//Poppins font
$cl_poppins_font-path: '../fonts/poppins/';
$cl_poppins_font-family: 'Poppins-Regular', Helvetica, Arial, sans-serif;
$cl_poppins_font-family-medium: 'Poppins-Medium', Helvetica, Arial, sans-serif;
$cl_poppins_font-family-Light: 'Poppins-Light', Helvetica, Arial, sans-serif;
$cl_poppins_font-family-Bold: 'Poppins-Bold', Helvetica, Arial, sans-serif;
$cl_poppins_font-family-SemiBold: 'Poppins-SemiBold', Helvetica, Arial, sans-serif;

// susy configuration
$susy: (
  columns: 24,
  gutter-position: 'split',
  container: 1200px,
  gutters: 1
);

// media query
$cl_min: 320px;
$cl_mobile: 479px;
$cl_sm: 768px;
$cl_md: 992px;
$cl_lg: 1025px;
$cl_xl: 1200px;

// font size
$cl_font-size: 16px;
$cl_font-line-height: 21px;
@media only screen and (min-width: $cl_sm) {
  $cl_font-size: 16px;
  $cl_font-line-height: 24px;
}
@media only screen and (min-width: $cl_lg) {
  $cl_font-size: 18px;
  $cl_font-line-height: 27px;
}
$cl_font-color: $charcol;

// include typography
@import "typography";
