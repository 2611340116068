// Tabs
//
// Layout Tabs
//
// Markup: tabs.twig
//
// Style guide: layout.tabs
.tabs {
  width: 100%;
  background: $clear_blue;
  font-family: $cl_intel_font-family-bold;
  font-size: 14px;
  color: $white;
  letter-spacing: 0;
  display: inline-flex;
  height: 64px;

  .container {
    display: inline-flex;
  }

  .tabs__table_of_content {
    font-size: 16px;
    text-align:left;
    position: relative;
    top: 19px;

    a {
      color: $white;
    }

    &:before {
      font-family: FontAwesome;
      content: '\f0ca';
      font-size: 14px;
      font-weight: normal;
      color: $intel;
      padding: 5px;
      margin-right: 15px;
      background: $gray;
      border-radius: 2px;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .tabs__menu {
    display: none;

    .tabs__menu_title {
      font-family: $cl_intel_font-family;
      color: $black_light;
      font-size: 16px;
      line-height: 49px;
      text-align: left;
    }

    .tabs__menu_list {
      list-style: none;
      margin: 0;
      padding: 0;

      .tabs__menu_list_item {
        min-height: 32px;
        text-align: left;
        display: block;
        font-family: $cl_intel_font-family;
        font-size: 14px;
        font-weight: normal;
        color: $dark_gray;
        letter-spacing: 0;
        text-align: left;

        .tabs__menu_list_parent__icon {
          color: $intel;
          position: relative;
          top: 7px;
          left: 5px;
          width: 15px;
          float: left;
          display: inline-flex;
          cursor: pointer;
        }

        .tabs__menu_list__child {
          display: none;
          list-style: none;
          background-color: $white;
          font-family: $cl_intel_font-family;
          padding: 0;
          padding-left: 30px;
          min-height: 32px;
          margin-top: 10px;

          &.collapsed {
            display: block;
          }

          .tabs__menu_list__child_item {
            list-style: none;
            min-height: 32px;
            padding: 0;

            &:before {
              content: '';
              margin: 0;
              text-indent: 0;
            }

            &:hover {
              a {
                color: $intel;
              }
            }

            a {
              font-family: $cl_intel_font-family;
              color: $dark_gray;
              font-size: 14px;
              font-weight: normal;
              color: $dark_gray;
              letter-spacing: 0;
              text-align: left;
              left: 20px;
            }

             &.tabs__menu_list_parent a {
              position: relative;
              top: 5px;
              left: 5px;
            }

            .tabs__menu_list__child_item {
              list-style: none;

              &:hover {
                a {
                  color: $intel;
                }
              }

              a {
                color: $dark_gray;
              }
            }
          }
        }

        &:hover, &:focus, &:active, &.active, &.active-trial {
          background: $gray_light_2;

          a {
            color: $intel;
          }
        }

        a {
          color: $dark_gray;
          text-decoration: none;
          position: relative;
          top: 5px;
          left: 20px;
        }

        &.tabs__menu_list_parent a {
          color: $dark_gray;
          text-decoration: none;
          position: relative;
          top: 5px;
          left: 5px;
        }

      }
    }
  }

  .tabs__menu.opened {
    display: block;
    z-index: 2;
    position: absolute;
    height: 100%;
    left: 0;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 80%;
    padding: 32px;
  }

}

#tabs__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;

  &.active {
    display: block;
  }

  &:not(.active) {
    display: none;
  }
}

@media only screen and (min-width: $cl_lg) {
  .tabs {

    .tabs__table_of_content {
      font-size: 14px;
      font-weight: normal;

       &:before {
         display: none;
       }
    }

    .tabs__menu, .tabs__menu.opened {
      margin-left: 15px;
      display: block;
      z-index: 0;
      position: relative;
      height: 60px;
      left: auto;
      background: none;
      box-shadow: none;
      padding: 0;

      .tabs__menu_title{
        display: none;
      }

      .tabs__menu_list {
        list-style: none;
        margin: 0px;

        .tabs__menu_list_item {
          height: 64px;
          padding: 0 15px;
          text-align: center;
          display: inline-flex;
          margin-right: -5px;

          a,
          &.tabs__menu_list_parent a {
           color: $white;
           position: relative;
           top: 19px;
           left: 0;
          }

          .tabs__menu_list_parent__icon {
            display: none;
          }

          .tabs__menu_list__child, .tabs__menu_list__child.collapsed {
            display: none;
          }

          &:hover, &:focus, &:active, &.active, &.active-trial {
            background: $white;

            a {
              color: $intel;
            }
          }

          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }

  #tabs__overlay, #tabs__overlay.active {
    display: none;
  }
}
