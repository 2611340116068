// Social Channel Menu 
//
// Component Social Channel Menu
//
// Markup: social_channel_menu.twig
//
// Style guide: components.social_channel_menu
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.social_channel_menu {
  width: 100%;

  .social_channel_list__items {
    list-style-type: none;
    margin: $comp_space_md 0;
    padding: 0;
  }

  .social_channel_list__call_to_action {
    text-align: center;
    margin-bottom: $comp_space_md;
  }
}
