$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

html, body {
  margin: 0;
  padding: 0;
  background: $background;
  font-family: $cl_intel_font-family;
  font-size: $cl_font-size;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $cl_font-line-height;
  color: $cl_font-color;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 150ms ease-in-out;
  width: 100%;
}
html {
  &.active {
    margin-left: 250px;
    overflow-y: hidden;
  }
}

p.standard,
span.standard {
  font-family: $cl_intel_font-family;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

p.big,
span.big {
  font-family: $cl_intel_font-family;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

p.small,
span.small {
  font-family: $cl_intel_font-family;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $cl_intel_font-family;
}

h1 {
  font-weight: lighter;
  font-size: 36px;
  letter-spacing: 0.36px;
  line-height: 45px;

  &.heroe {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.49px;
  }
}

h2 {
  font-weight: lighter;
  font-size: 30px;
  letter-spacing: 0.37px;
  line-height: 37.5px;
}

h3 {
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-family: $cl_intel_font-family-bold;
  font-size: 20px;
  line-height: 25px;
}

h5 {
  font-family: $cl_intel_font-family-bold;
  font-size: 18px;
  line-height: 22.5px;
}

label {
  font-family: $cl_intel_font-family-bold;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0;
  color: $button;
}

.toolbar__container label {
  font-family: $cl_intel_font-family;
  font-weight: normal;
  color: $white;
}

h6 {
  font-family: $cl_intel_font-family-bold;
  font-size: 16px;
  line-height: 20px;
}

ul, ol {
  font-family: $cl_intel_font-family;
  li {
    margin-bottom: 16px;
    ul, ol {
      margin-top: 8px;
      margin-bottom: 25px;

      li {
        margin-left: 14px;
        margin-bottom: 8px;
      }
    }

    > p,
    p.first {
      margin-bottom: 0;
    }
  }
  ul, ol {
    padding-left: 8px;
  }
}

ul {
  list-style-type: disc;
  li {
    ul {
      list-style-type: none;
      li {
        &:before {
          content: '- ';
          padding-right: 5px;
        }
        ol {
          list-style-type: lower-roman;
        }
      }
    }
    ol {
      list-style-type: lower-alpha;
      li {
        ol {
          list-style-type: lower-roman;
        }
      }
    }
  }
}

ol {
  list-style-type: decimal;
  li {
    ul {
    list-style-type: none;
     li {
       p {
         display: inline-block;
       }
        &:before {
          content: '- ';
        }
        ol {
          list-style-type: lower-roman;
        }
      }
    }
    ol {
      list-style-type: lower-alpha;
      li {
        ol {
          list-style-type: lower-roman;
        }
      }
    }
  }
}

blockquote {
  width: 75%;
  font-family: $cl_intel_font-family;
  font-size: 20px;
  line-height: 30px;
  margin: 40px auto 40px auto;
  padding: 20px;
  background-color: $gray_light;
}

header,
footer {
  ul, ol {
    margin: 0;
    padding: 0;
  }
}

a,
a:visited {
  color: $intel;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

.container {
  @include container();
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}

.Section {
  margin: 0;
}

.section {
  p {
    margin-top: $comp_space_sm;
  }
}

.block-views-blocktop-stories-sidebar-block-1 {
  h2 {
    font-size: 1em;
    font-family: $cl_poppins_font-family-Light;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
}

.owl-nav {
  position: absolute;
  width: 100%;
  top: 47%;
  .owl-prev, .owl-next {
    color: transparent;
    width: 10px;
    text-align: center;
    position: absolute;
    &:before {
      font: normal normal normal 16px/1 FontAwesome;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $dark;
    }
  }
  .owl-prev {
    left: 0;
    &:before {
      content: "\f053";
    }
  }
  .owl-next {
    right: 0;
    &:before {
      content: "\f054";
    }
  }
}

.node--unpublished {
  border: 2px dotted $wipinbell;
  background-color: transparent;
  background-color: rgba($wipinbell, 0.1);
  padding: $comp_space_sm;
}

.view-header {
  font-family: $cl_intel_font-family;
  font-weight: lighter;
  font-size: 32px;
  color: $black_light;
  line-height: 37.5px;
  text-align: left;
  padding-bottom: 32px;
}

.align-left {
  margin-right: 10px;
}

.align-right {
  margin-left: 10px;
}

img {
  max-width: 100%;
  height: auto;
}

code.docutils {
  background: $gray;
  padding: 3px 5px;

  &.file {
    font-family: monospace,monospace;
    font-weight: bold;
  }
}
pre {
  background: $dark;
  color: $white;
  padding: 3px 10px;
  text-align: left;

  .hljs-keyword {
    color: $code-keywords;
  }
}
/*** Documentation ****/

.nodetype--documentation{
  img {
    max-width: 100%;
  }

  .section {
    margin-top: 20px;
    ol.arabic {
      li ul {
        li {
          p.first {
            display: inline-block;
          }
        }
      }
    }
  }
}
.menuselection {
  background: $gray;
  font-family: monospace,monospace;
  font-weight: bold;
  font-size: 1em;
  padding: 3px 5px;
}
a.image-reference {
  display: block;
}
.figure {
  .caption {
    font-size: $cl_font-size - 1;
    font-style: italic;
    text-align: center;
    margin-bottom: $comp_space_md !important;
  }
}
.guilabel {
  font-weight: bold;
  background: $gray;
  font-family: monospace,monospace;
  font-size: 1em;
  padding: 3px 5px;
}
.text-highlight {
  color: $intel;
  font-family: $cl_intel_font-family-bold;
}
.normal {
  color: $cl_font-color;
}

article {
  &.embedded-entity {
    &.align-center {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    &.align-right {
      img {
        position: relative;
        right: 0;
      }
    }
  }
}

.content-table {

  tr {

    th,
    td {
      vertical-align: top!important;
    }
  }
}

.alias--stacks,
.alias--sw-stacks {

  .Column__item {

    .call_to_action {
      max-width: 100%!important;
      padding: 15px 20px!important;
    }

    .Image__item {
      
      .Image__description {
        display: none!important;
      }
    }
  }
}