// Feature List
//
// Component Feature List
//
// Markup: feature_list.twig
//
// Style guide: components.feature_list
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.block--even {
  @extend %bg-gradient--blue;
}

.block--odd {
  @extend %bg-gradient--light;
}

.feature_list {
  margin: 0;
  padding: 0;
  list-style: none;
  
  .Column {
    margin: 0;
  }

  li {
  	font-family: $cl_intel_font-family;
  }

  .column__icon {
    text-align: center;
  }

  .column__text {
  	&.column__left {
  		text-align: right;
  	}
  }

  .call_to_action__secondary {
  	margin-top: $comp_space_md;
  }
}
