// List Card
//
// Components list card
//
// Markup: list_card.twig
//
// Style guide: components.list_card
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.list-card {
  box-sizing: border-box;
  padding-bottom: $comp_space_sm;
  padding-top: $comp_space_sm;

  @media (min-width: $cl_md) {
    @include span(8);
  }

  @media (min-width: $cl_xl) {
    @include span(6);
  }

  &__ul {
    list-style-type: none;
    padding-left: 0;
  }

  &__item {
    border-bottom: 1px solid $submenu_border;
    margin-bottom: 0;
    padding: $comp_space_sm 0;

    &:last-child {
      border-bottom: none;
    }

    &__title {
      margin-bottom: $comp_space_sm;
    }
  }
}
