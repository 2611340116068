// Card Blog
//
// Component Card Blog
//
// Markup: card_blog.twig
//
// .search_result - Style 2 - No image and border
//
// Style guide: components.card_blog
.view-latest-blog-posts,
.view-landing-pages {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: $padding_big;

  .view-content {

    .views-infinite-scroll-content-wrapper {

      @media only screen and (min-width: $cl-sm) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        flex: 1 0 auto;

        .views-row {
          display: flex;
          margin: 0;
          margin-top: $padding_small;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .views-row {

        &:first-child {
          margin-top: 20px!important;
        }

        .card_blog {
          background: $white;
          font-size: $fsz-tile-body;
          line-height: $lh-tile-body;
          height: 100%;

          &, a {
            color: $text;
          }

          &__image,
          &__wrapper {
            box-sizing: border-box;
            width: 100%;
          }

          &__image {
            width: 100%;
            height: 165px;
            display: block;
            text-align: center;
            position: relative;
            overflow: hidden;

            a {
              width: 100%;
              height: 100%;

              img {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
              }
            }
          }

          &__wrapper {
            padding: $padding_card;

            &--categories {
              font-size: $fsz-tile-aditional;
              color: $text-light;

              a {
                color: $text-light;
              }
            }

            &--title {
              margin-top: $padding_small;

              h2 {
                font-family: $cl_intel_font-family-bold;
                font-size: $fsz-tile-heading;
                line-height: $lh-tile-heading;
                margin: 0;
              }
            }

            &--description {
              margin-top: $padding_small;
            }

            &--footer {
              font-size: $fsz-tile-aditional;
              color: $text-light;

              &-authors, &-date {
                margin-top: $padding_small;
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.view-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  form {
    width: 100%;

    .form--inline {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      .form-type-select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        float: initial!important;
        position: relative;
  
        &:before {
          font-size: $fsz-tile-body;
          line-height: $lh-tile-body;
          color: $white;
          top: 45%;
          left: initial;
          right: 15px;
          transform: translateY(-50%);
          position: absolute;
        }
  
        label {
          font-size: $fsz-tile-body;
          line-height: $lh-tile-body;
  
          &:after {
            content: ':';
          }
        }
  
        select {
          margin: 0;
          margin-left: $padding_small;
          background: $teal;
          color: $white;
          width: auto;
          height: auto;
          padding: 9px 35px 9px 18px;
          font-size: $fsz-tile-link;
        }
      }
    }
  }
}