// Social Channel
//
// Components Social Channel
//
// Markup: social_channel.twig
//
// Style guide: components.social_channel

.social_channel {
  display: block;
  width: 100%;
  border-bottom: 1px solid $gray;

  .social_channel__icon {
    margin: 20px;
    text-align: center;

    i {
      font-size: 36px;
      color: $dark_gray;
    }
  }

  .social_channel__details {

    .social_channel__title {
      font-size: 20px;
      font-family: $cl_intel_font-family-bold;
      color: $dark_gray;
      line-height: 30px;
      text-align: left;
      margin: 23px 0 14px 0 !important;
      text-align: center;

      a {
        color: $dark_gray !important;
      }
    }

    .social_channel__description {
      margin-bottom: 20px;
      font-family: $cl_intel_font-family;
      font-size: 16px;
      font-weight: normal;
      color: $dark_gray;
      line-height: 24px;
      text-align: center;
      padding: 0 24px;
    }
  }
}

@media only screen and (min-width: 480px) {
  .social_channel {
    display: inline-flex;

    .social_channel__icon {
      text-align: left;
    }

    .social_channel__details {

      .social_channel__title {
        text-align: left;
      }

      .social_channel__description {
        text-align: left;
        padding: 0 24px 0 0;
      }
    }
  }
}
