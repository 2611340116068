@font-face {
  font-family: 'IntelClear-Regular';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_Rg.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Rg.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Rg.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Rg.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClear-Light';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_Lt.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Lt.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Lt.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Lt.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClear-Bold';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_Bd.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Bd.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Bd.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_Bd.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClear-Italic';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_It.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_It.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_It.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_It.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClear-LightItalic';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_LtIt.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_LtIt.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_LtIt.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_LtIt.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClear-BoldItalic';
  src: url('#{$cl_intel_font-path}IntelClear_WLat_BdIt.eot'),
       url('#{$cl_intel_font-path}IntelClear_WLat_BdIt.eot#iefix') format('embedded-opentype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_BdIt.ttf') format('truetype'),
       url('#{$cl_intel_font-path}IntelClear_WLat_BdIt.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'IntelClearPro-Bold';
  src: url('#{$cl_intel_font-path}IntelClearPro_W_Bd.eot');
  src: url('#{$cl_intel_font-path}IntelClearPro_W_Bd.eot?#iefix') format('embedded-opentype');
  src: url('#{$cl_intel_font-path}IntelClearPro_W_Bd.ttf') format('truetype');
  src: url('#{$cl_intel_font-path}IntelClearPro_W_Bd.woff') format('woff');
  font-weight: normal;
}

// Poppins fonts

@font-face {
  font-family: 'Poppins-Regular';
  src: url('#{$cl_poppins_font-path}Poppins-Regular.ttf') format('truetype');
  src: url('#{$cl_poppins_font-path}poppins-regular-webfont.woff') format('woff');
  src: url('#{$cl_poppins_font-path}poppins-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Light';
  src: url('#{$cl_poppins_font-path}Poppins-Light.ttf') format('truetype');
  src: url('#{$cl_poppins_font-path}poppins-light-webfont.woff') format('woff');
  src: url('#{$cl_poppins_font-path}poppins-light-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('#{$cl_poppins_font-path}Poppins-Bold.ttf') format('truetype');
  src: url('#{$cl_poppins_font-path}poppins-bold-webfont.woff') format('woff');
  src: url('#{$cl_poppins_font-path}poppins-bold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('#{$cl_poppins_font-path}Poppins-SemiBold.ttf') format('truetype');
  src: url('#{$cl_poppins_font-path}poppins-semibold-webfont.woff') format('woff');
  src: url('#{$cl_poppins_font-path}poppins-semibold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('#{$cl_poppins_font-path}Poppins-Medium.ttf') format('truetype');
  src: url('#{$cl_poppins_font-path}poppins-medium-webfont.woff') format('woff');
  src: url('#{$cl_poppins_font-path}poppins-medium-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$fsz-main-feature-heading: 36px;
$fsz-main-feature-body: 24px;
$lh-main-feature-body: 36px;

$fsz-tile-heading: 24px;
$lh-tile-heading: 28px;
$fsz-tile-body: 16px;
$lh-tile-body: 24px;
$fsz-tile-aditional: 14px;
$fsz-tile-link: 16px;

$fsz-app-tile-heading: 18px;
$fsz-app-tile-body: 14px;
$fsz-app-tile-aditional: 12px;
$fsz-app-tile-link: 12px;

$fsz-download-heading: 24px;
$fsz-download-body: 18px;
$ls-download-body: 24px;
$fsz-download-link: 16px;

$fsz-category-menu: 16px;
$fsz-code-sample: 16px;
$fsz-button: 12px;
$fsz-tab-text: 12px;
