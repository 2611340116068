// Downloads Content
//
// Layout Downloads Content
//
// Markup: downloads_content.twig
//
// Style guide: layout.downloads_content
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.downloads {
  &__all {
    padding: $comp_space_md 0;

    &__subtitle {
      color: $intel;
      font-family: $cl_intel_font-family-bold;
    }

    &__subdesc {
      margin-bottom: $comp_space_sm;
    }

    .download-card {
      @include span(24);
      border: 1px solid $border_footer;
      margin: $comp_space_sm 0;
      padding: $comp_space_sm;
    }

    .download-card__action__cta {
      margin-top: $comp_space_sm;

      .call_to_action.image {
        min-width: 104.609px;
        text-align: left;
      }
    }

    @media (min-width: $cl_md) {
      .download-card__action__description {
        @include span(12);
      }

      .download-card__action__cta {
        @include span(12);
      }
    }

    @media (min-width: $cl_xl) {
      .download-card__action__description {
        @include span(14);
      }

      .download-card__action__cta {
        @include span(10);
      }
    }

    .download-card__desc {
      display: none;
    }
  }

  &__pinned {
    border-bottom: 2px solid $submenu_border;
    padding: $comp_space_md 0;

    .download-card {
      &__action__description {
        display: none;
      }

      .signature {
        display: none;
      }
    }

    .download-card__action__cta {
      .call_to_action.image {
        min-width: 89.750px;
        text-align: left;
      }
    }

    &.total__1 {
      .download-card {
        @media (min-width: $cl_md) {
          @include span(24);
        }

        @media (min-width: $cl_xl) {
          @include span(24);
        }
      }
    }

    &.total__2 {
      .download-card,
      .list-card {
        @media (min-width: $cl_md) {
          @include span(12);
        }

        @media (min-width: $cl_xl) {
          @include span(12);
        }
      }
    }

    &.total__3 {
      .download-card,
      .list-card {
        @media (min-width: $cl_md) {
          @include span(8);
        }

        @media (min-width: $cl_xl) {
          @include span(8);
        }
      }
    }
  }
}
