// Highlight
//
// Components Highlight
//
// Markup: highlight.twig
//
// Style guide: components.highlight
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.highlight {
  text-align: center;
  font-family: $cl_intel_font-family;
  padding: 0 10px;

  .highlight__icon {
    display: block;
    font-size: 100px;
  }

  .highlight__title {
    font-size: 24px;
    font-family: inherit;
    line-height: 30px;
    color: $black_light;
    padding-bottom: 19px;
  }

  .highlight__description {
    font-size: 16px;
    font-family: inherit;
    line-height:24px;
    color: $dark_gray;
    padding-top: 19px;
  }

  .call_to_action__secondary {
    margin-top: $comp_space_md;
  }
}

.dynamic_block.features {
  .highlight__title,
  .highlight__description {
    color: $white;
  }
}
