// Project
//
// Components Project
//
// Markup: project.twig
//
// Style guide: components.project
.Project {
  width: 100%;
  &__item {
    padding: 40px;
    min-height: 340px;
    background: $pantone_541;
    box-sizing: border-box;
    font-size: 16px;
    color: $white;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    position: relative;
  }
  &__header {
    display: block;
    overflow: hidden;
    min-height: 85px;
    img {
      float: left;
      width: 45px!important;
      height: 65px;
      margin-right: 25px;
    }
  }
  &__title {
    float: right;
    width: calc(100% - 70px);
    p {
      font-family: $cl_intel_font-family-light;
      font-size: 1.875em;
      font-weight: 300;
      line-height: 1em;
      strong {
        font-family: $cl_intel_font-family-bold;
      }
    }
    small {
      font-family: $cl_intel_font-family-light;
      font-size: 1em;
      font-weight: 300;
      line-height: 1.3125em;
    }
    &.no-image {
      width: 100%;
    }
  }
  &__body {
    display: block;
    padding: 20px 0 30px 0;
    p {
      font-family: $cl_intel_font-family-bold;
      font-size: 1em;
      line-height: 1.5em;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    padding-bottom: inherit;
  }
  .owl-nav {
    .owl-prev, .owl-next {
      &:before {
        color: $white;
      }
    }
    .owl-prev {
      left: 15px;
    }
    .owl-next {
      right: 15px;
    }
  }
}
