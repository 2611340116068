#software {
  .get-bundle-btn {
    color: $white !important;
    background-color: #33bef2;
    border-radius: 30px;
    border: 2px solid transparent;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 20px;
    text-transform: uppercase;

    &:hover {
      border: 2px solid $clear_blue;
      background-color: $button_blue_hover;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }
}

#bundle {
  .links__wrapper {
    .field__item {
      a {
        background-color: $white;
        border-top: 1px solid $gray_light_2;
        border-bottom: 1px solid $gray_light_2;
        color: $pantone_cyan;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        font-family: $cl_intel_font-family-bold;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.16px;
        padding: 5px 40px 5px 0;
        text-align: center;

        &:active,
        &:focus,
        &:hover {
          color: $clear_blue;
          background-color: $white;
        }

        &:after {
          content: '\f101';
          font: normal normal normal 16px/1 FontAwesome;
          font-weight: 700;
          display: inline-block;
          vertical-align: middle;
          padding-left: 5px;
        }
      }
    }

    .field__item:last-child {
      a {
        border-top: 1px solid transparent;
      }
    }

    .field__item:first-child {
      a {
        border-top: 1px solid $gray_light_2 !important;
      }
    }
  }
}

@media only screen and (max-width: $cl_sm) {
  #bundle {
    .links__wrapper {
      .field__item:last-child {
        a {
          border-top: 1px solid $gray_light_2 !important;
        }
      }
    }
  }
}
