// Image gallery
//
// Components Image gallery
//
// Markup: image_gallery.twig
//
//
// Style guide: components.image_gallery
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.image_gallery__wrapper {
  font-family: $cl_intel_font-family;
  text-align: center;
  width: 100%;

  .image_gallery {
    border-bottom: 0;
  }

  .image_gallery__title {
    color: $intel;
    text-transform: uppercase;
    font-family: inherit;
    margin-bottom: $comp_space_md;
  }

  .image_gallery {
    .owl-nav {
      position: absolute;
      width: 100%;
      top: 90%;

      .owl-prev,
      .owl-next {
        &::before {
          background-color: $white;
          color: $charcol;
          border: solid 1px $gray_light_2;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 100%;
          padding: 8px 8px 6px 8px;
          font-size: 14px;
        }
      }

      .owl-prev {
        left: 33%;
      }

      .owl-next {
        right: 34%;
      }
    }

    .owl-dots {
      height: 32px;
      max-width: 26%;
      background-color: rgba($gray, 0.5);
      display: inline-flex;
      padding: 0 10px;
      margin-top: 40px;

      .owl-dot {
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background: $charcol;
        margin: 0 5px;
        position: relative;
        top: 10px;
        bottom: 10px;

        &.active {
          background: $pantone_cyan;
        }
      }
    }
  }

  &._with-icon {
    .owl-stage {
      display: flex;
      align-items: center;
    }

    .owl-item {
      display: inline-block;
      height: 100%;
    }

    .image_gallery {
      .owl-nav {
        top: 95%;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .image_gallery__container {

      .image_gallery {
        .owl-dots {
          height: 20px;
          padding: 0 5px;
          max-width: 66%;

          .owl-dot {
            width: 7px;
            height: 7px;
            top: 6px;
            bottom: 6px;
          }
        }
      }
    }
  }
}
