// Article
//
// Component Article
//
// Markup: article.twig
//
// .search_result - Style 2 - No image and border
//
// Style guide: components.article
$comp_space_sm: if($space_sm, $space_sm, 16px);
$comp_space_md: if($space_md, $space_md, 32px);
$comp_space_lg: if($space_lg, $space_lg, 64px);
$comp_space_xl: if($space_xl, $space_xl, 96px);

.article {
  font-family: $cl_intel_font-family;
  color: $gray_alt;
  display: flex;
  position: relative;
  margin-bottom: $comp_space_md;
  align-items: flex-start;
  padding: 0;

  &.search_result {
    border-bottom: 1px solid $grayish;
    padding: 0 0 $comp_space_md 0;
  }

  .article__info {
    display: block;
    width: 100%;

    .article__title {
      font-family: $cl_intel_font-family-bold;
      font-size: 16px;
      line-height: 22.5px;
      text-align: left;
      color: $black_light;
      margin-top: 0;
      margin-bottom: $comp_space_sm;

      a {
        color: $black_light;

        &:visited {
          color: $black_light;
        }

        &:hover,
        &:active,
        &:focus {
          color: $intel;
        }
      }
    }

    .article__details {
      margin-top: $comp_space_sm;
      font-size: 14px;
      letter-spacing: 0.12px;
      text-align: left;
      display: none;

      .article__date {
        &:after {
          content: "|";
          padding: 0 10px;
        }
      }

      .article__categories,
      .article__author {
        a {
          color: $intel;

          &:visited {
            color: $intel;
          }

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      .article__author {
        color: $intel;

        &:visited {
          color: $intel;
        }

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .article__description {
      margin-top: $comp_space_sm;
      margin-bottom: $comp_space_md;
      display: none;

      .article__description_text {
        font-size: 16px;
        line-height: 29px;
        text-align: left;
      }
    }

    .article__link {
      margin-top: $comp_space_sm;
      color: $intel;
      font-size: 14px;
      letter-spacing: 0.36px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .article__image_wrapper {
    margin-left: $comp_space_md;

    .article__image, img {
      width: 110px;
      height: auto;
    }
  }
}

@media only screen and (min-width: $cl_sm) {
  .article {
    margin-top: $comp_space_md;

    .article__info {
      .article__title {
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 0;
      }

      .article__details, .article__description {
        display: block;
      }
    }

    .article__image_wrapper {
      margin-left: $comp_space_lg;

      .article__image, img {
        width: 200px;
        height: auto;
      }
    }
  }
}
