// Cards icon list
//
// Components Cards icon list
//
// Markup: cards_icon_list.twig
//
// Style guide: components.cards_icon_list

.cards_icon_list {
  display: flex;

  &__flex-wraper {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    margin: auto;

    @media only screen and (min-width: $cl_sm) {
      flex-direction: row;
      justify-content: flex-start;
      width: calc(100% + 20px);
      margin: 0 -10px;
      align-items: initial;

      .alias--developer & {
        display: initial;
        column-count: 2;
        width: 100%;
        column-gap: 20px;
        flex-direction: initial;
        justify-content: initial;
        margin: 0;
      }
    }
  }

  h2 {
    text-align: center;
    margin: $space_md;
    color: $intel;
    font-family: $cl_intel_font-family-bold;
  }
}