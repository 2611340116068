// Image
//
// Components Image
//
// Markup: block_image.twig
//
// Style guide: components.image
.Image {
  width: 100%;

  &__item {
    width: 100%;
    max-width: 990px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  &__top {
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__description {
    width: auto;
    padding: 10px 0;
    font-family: $cl_intel_font-family-bold;
    p {
      font-size: 0.875em;
      color: $gastly;
      a {
        color: $intel;
        text-decoration: none;
      }
    }
  }
}
