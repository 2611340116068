// Link
//
// Components Link
//
// Markup: link.twig
//
// Style guide: components.link

a {
  &.link {
    display: block;
    color: $white;
    font-family: $cl_intel_font-family-bold;
    font-size: $fsz-tile-link;
  
    i {
      margin-left: 5px;
  
      &.fa {
        font-size: 16px;
      }
    }
  
    &.button__color_1 {
      color: $text;
    }
  
    &.button__color_2 {
      color: $purple;
    }
  
    &.button__color_3 {
      color: $teal;
    }

    &.button__color_4 {
      color: $blue;
    }
  }
}