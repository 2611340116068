.Error {
	margin: 10%;
	&__image {
		@include span(3 of 12);
	}
	&__description {
		@include span(9 of 12);
		font-size: 16px;
		h1 {
			font-family: $cl_poppins_font-family-Light;
			font-size: 4em;
			color: $menu_border;
			line-height: 1.125em;
		}
		h2 {
			font-family: $cl_poppins_font-family-SemiBold;
			font-size: 2.25em;
			color: $button;
			line-height: 2.125em;
		}
		p {
			font-family: $cl_intel_font-family-light;
			font-size: 1.25em;
			letter-spacing:0.15px;
			color: $button;
			strong {
				font-family: $cl_poppins_font-family-SemiBold;
				line-height: 30px;
			}
			a {
				font-family: $cl_intel_font-family-light;
				color: $menu_border;
			}
		}
		ul {
			margin-left: 20px;
			li {
				font-family: $cl_intel_font-family-light;
				font-size: 1.25em;
				color: $button;
				a {
					font-family: $cl_intel_font-family-light;
					color: $menu_border;
				}
			}
		}
	}
}