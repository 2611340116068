// Video
//
// Components Video
//
// Markup: video.twig
//
// Style guide: components.video
.Video {
  width: 100%;
  min-height: 400px;
  &-small {
      max-width: 300px
  }
  &__item {
    width: 100%;
    max-width: 990px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  &__top {
    width: 100%;
    height: 155px;
    position: relative;
    @media only screen and (min-width: $cl_mobile) {
      height: 250px;
    }
    @media only screen and (min-width: $cl_sm) {
      height: 360px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__play {
    display: block;
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background: url(../images/structure/play_video.png)no-repeat center top;
    background-size: cover;
    opacity: 1;
    cursor: pointer;
    @media only screen and (min-width: $cl_mobile) {
      left: calc(50% - 40px);
      top: calc(50% - 40px);
      width: 80px;
      height: 80px;
    }
    @media only screen and (min-width: $cl_sm) {
      left: calc(50% - 61px);
      top: calc(50% - 61px);
      width: 122px;
      height: 122px;
    }
    &:hover {
      opacity: 0.5;
    }
    &:focus {
      outline: none;
    }
  }
  &__description {
    width: auto;
    padding: 10px 0;
    font-family: $cl_intel_font-family-bold;
    p {
      font-size: 0.875em;
      color: $gastly;
      a {
        color: $intel;
        text-decoration: none;
      }
    }
  }
}

#cboxOverlay {
  background: $dark;
}

#cboxClose {
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white;
  font-size: 1.5em;
  background: none;
  top: -22px;
  text-indent: 0;
  width: 19px;
  &:before {
    box-sizing: border-box;
    content: "\f00d";
  }
}
