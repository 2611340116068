// Button
//
// Components Button
//
// Markup: button.twig
//
// Style guide: components.button
.Button {
  display: inline-block;
  padding: 17px 6px 12px 6px;
  background-color: transparent;
  font-size: 16px;
  color: $white;
  font-family: $cl_intel_font-family-bold;
  text-align: center;
  line-height: 1.3125em;
  border: solid 1px $white;
  min-width: 150px;
}
