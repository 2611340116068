// Card_stack
//
// Components Card_stack
//
// Markup: card_stack.twig
//
// Style guide: components.card_stack
.card_stack {
  position: relative;
  background: $white;
  padding: $padding_card;
  margin: 0 auto 25px auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  font-size: $fsz-tile-body;
  line-height: $lh-tile-body;

  .card_stack__title {
    position: relative;

    h3 {
      padding-right: 45px;
      font-size: $fsz-tile-heading;
      line-height: $lh-tile-heading;
    }
  }

  &__content {

    &.hide {
      display: none;
    }
  }

  &__metadata {
    color: $text-light;
    display: none;
    font-size: $fsz-tile-aditional;
    margin-bottom: $space_md;
  }

  &.bg_color_1 {
    background: $blue-light;
  }

  &.bg_color_2 {
    background: $gray;
  }
  
  &.bg_color_3 {
    background: $purple-light;
  }
  
  &.bg_color_4 {
    background: $teal-light;
  }

  &.two-cols {
    align-items: initial; 

    .cols {
      display: flex;
      flex-direction: column;
  
      &:nth-child(2) {
        margin-top: $padding_small;
        margin-bottom: $padding_small;
      }

      &:last-child {
        align-self: center;
      }
  
      @media only screen and (min-width: $cl_sm) {
        width: calc(100% * 1/2 - 20px);
  
        &:nth-child(2) {
          margin-top: 0;
          margin-bottom: initial;
        }
      }
    }
  }

  > div {
    width: 100%;

    h3 {
      font-family: $cl_intel_font-family-bold;
    }

    h3, .card_stack__content {
      margin-bottom: $padding_small;
    }

    img {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .call_to_action {
    max-width: max-content !important;
    min-width: 90px;
    margin-bottom: 0;
    padding: 7px 20px;
  }

  &__buttons {
    position: relative;
  }
}

.card_stack {
  .card_stack__title {
    .fa {
      display: block;
      cursor: pointer;
      font-size: $fsz-tile-heading;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__metadata {
    p {
      font-size: 13px;
      line-height: 20px;
      margin-top: 25px;
    }
  }
}

.buttons__lts {
  margin-top: 1rem;
}
