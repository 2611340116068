// Blog Detail
//
// Layout Blog Detail
//
// Markup: blog.twig
//
// Style guide: layout.blog
.nodetype--blog {
  
  main {
    background: $white;
    max-width: 900px!important;
    font-size: $fsz-tile-body;
    line-height: $lh-tile-body;
    margin-top: $padding_medium!important;
    margin-bottom: $padding_big!important;
  }
}

.post-image {
  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}
.post-image--caption {
  text-align: right;
}

.blog_detail {
  background: $white;
  max-width: 100%;
  font-size: $fsz-tile-body;
  line-height: $lh-tile-body;


  &__categories {
    display: inline-block;
    color: $blue;
    font-size: $fsz-tile-link;

    a {
      color: $blue;
      font-family: $cl_intel_font-family-bold;
    }
  }

  &__title {
    color: $text;
    font-family: $cl_intel_font-family-bold;
    margin: $padding_small 0;
    font-size: $fsz-tile-heading;
    line-height: $lh-tile-heading;
  }
    
  &__author {
    color: $text-light;
    font-family: $cl_intel_font-family-bold;
  }

  &__date {
    font-size: $fsz-tile-aditional;
    line-height: $fsz-tile-aditional;
    color: $text-light;
    margin-bottom: $padding_big;
    margin-top: 5px;
  }
}