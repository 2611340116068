#software {
  .search__results {
    .views-row {
      display: block;

      @media only screen and (min-width:$cl_sm) {
        margin-left: 10px!important;
        margin-right: 10px!important;
      }
    }

    .views-row:first-child {
      margin-top: 20px !important;
    }
  }

  .pager {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    float: right;
    margin-top: 25px;

    .pager__items {
      display: flex;
      list-style-type: none;
      padding: 0 8px;
      li {
        padding: 0 8px;
        a {
          color: $gray_alt;
          border-bottom: none;
          font-size: 16px;
        }
      }
    }

    .is-active a {
      color: $dark_gray;
      font-weight: bold;
    }

    .pager__item--next a {
      color: $dark_gray;
    }
  }

  .fa-chevron-right,
  .fa-chevron-left {
    font-family: FontAwesome;
    font-style: normal;
    font-size: 14px;
  }
}

@media only screen and (max-width: $cl_mobile) {
  #software {
    .pager {
      justify-content: center;
      padding: 0;
      .pager__items {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
