
//////////////////////////////////
// Primary Container Rules ///////
//////////////////////////////////

.cog--mq {
  @include container(80em);
  margin: 10px auto;
}

body{
  text-rendering: geometricPrecision;
}